.loader-container {
  position: fixed;
  background: #000000a1;
  z-index: 999999;
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}
.loader-container img {
  height: 20%;
  width: 20%;
}

.alert {
  position: fixed;
  top: 40px;
  right: 50px;
  width: 25%;
  padding: 15px;
  z-index: 9999;
  font-size: 18px;
  border: 0;
  border-radius: 12px;
}

.alert-shown {
  opacity: 1;
  transition: all 250ms linear;
}

.alert-hidden {
  opacity: 0;
  z-index: 0;
  transition: all 250ms linear 0s;
  transform: translateX(500px);
}
.alert-primary {
  background-color: #ffffff;
  color: #3093bb;
  font-weight: 500;
  box-shadow: 0px 4px 4px 0px #82dbff;
}
.alert-primary p {
  margin: 0px;
  font-size: 14px;
  color: #000000;
}
.alert-primary .button-accept {
  font-family: Mulish;
  font-size: 16px;
  font-weight: 600;
  line-height: 16px;
  border: none;
  background: #24b2b2;
  border-radius: 10px;
  padding: 15px 10px;
  color: #fff;
  margin: 0 10px;
}
.alert-primary .button-decline {
  font-family: Mulish;
  font-size: 16px;
  font-weight: 600;
  line-height: 16px;
  border: none;
  background: #e0416580;
  border-radius: 10px;
  padding: 15px 10px;
  color: #fff;
  margin: 0 10px;
}
.alert-success {
  background-color: #ffffff;
  color: #24b2b2;
  font-weight: 500;
  box-shadow: 0px 4px 4px 0px #24b2b282;
}
.alert-danger {
  background-color: #ffffff;
  color: #e04165;
  font-weight: 500;
  box-shadow: 0px 4px 4px 0px #e0416580;
}
.error {
  color: #e04165;
  font-size: 14px;
  line-height: 14px;
  font-weight: normal;
}

.my-select__indicator-separator {
  display: none;
}
.my-select__control {
  align-items: flex-start;
  background: none !important;
  line-height: 1.9;
  width: 100%;
  color: transparent;
  transition: all 0.28s ease;
  box-shadow: none;
  font-family: Mulish;
  font-weight: 600;
  font-size: 16px;
  min-height: 48px !important;
  color: #1d1929;
  border: 1px solid #ede8e9 !important;
  padding: 0.3rem !important;
  border-radius: 10px !important;
}
.my-select__multi-value {
  background: #24b2b2 !important;
  border-radius: 4px !important;
  padding: 5px 5px 8px 10px !important;
  color: #fff !important;
  font-family: "Mulish" !important;
}
.loader-item {
  background: #ffffff;
  border-radius: 12px;
  height: 150px;
  width: 150px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.nav-tabs .nav-link.active {
  background: none !important;
}
.accordion-button:focus {
  border: none !important;
  box-shadow: none !important;
}
.day_box input[type="checkbox"] {
  display: none;
}
.day_box input[type="checkbox"] + label {
  background: #ffffff;
  color: #9393aa;
  border: 1px solid #e0e0e0;
  padding: 7px;
  border-radius: 50px;
  height: 45px;
  width: 45px;
  text-align: center;
  font-weight: 600;
  font-size: 18px !important;
  opacity: 1 !important;
}
.day_box input[type="checkbox"]:checked + label {
  background: linear-gradient(45deg, #3093bb 0%, #82dbff 100%);
  color: #fff;
  border: 1px solid #e0e0e0;
  padding: 7px;
  border-radius: 50px;
  height: 45px;
  width: 45px;
  text-align: center;
  font-weight: 600;
  font-size: 18px !important;
}
.my-select__menu-list {
  padding-bottom: 0px;
  border: none;
  /* box-shadow: none; */
}
.my-select__option:last-child {
  border-bottom: none !important;
}
.my-select__option:hover {
  background-color: #deebff;
}
.my-select__option {
  border-bottom: 1px solid #f0f0f0;
  color: inherit;
  cursor: default;
  display: block;
  font-size: inherit;
  padding: 8px 12px;
  width: 100%;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  box-sizing: border-box;
}
.modal-mobile .modal-dialog.modal-dialog-centered {
  max-width: 330px !important;
}
.profile_tab_option_bg.nav-link {
  color: #9393aa;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.profile_tab_option_bg.nav-link.active {
  background: #f8fbff;
  border-radius: 8px;
}
.profile_tab_option_bg.nav-link.active .profile_option_text {
  color: #3093bb !important;
}
.profile_tab_option_bg.nav-link.active span .tab-title {
  display: none;
}
.profile_tab_option_bg.nav-link.active span .tab-title.active {
  display: block;
}
.profile_tab_option_bg.nav-link span {
  display: flex;
}
.profile_tab_option_bg.nav-link span .tab-title {
  display: block;
}
.profile_tab_option_bg.nav-link span .tab-title.active {
  display: none;
}

.dropdown-item-link {
  color: inherit;
  text-decoration: none;
  background-color: transparent;
}
.chat-icon {
  background: rgba(36, 178, 178, 0.1);
  padding: 10px;
  border-radius: 8px;
}
.title-bar {
  width: 6px;
  height: 19px;
  border-radius: 2px;
  margin: 7px 7px;
  padding: 0;
}
.m {
  background-color: #3093bb;
}
.l {
  background-color: #fc9875;
}
.r {
  background-color: #f8446c;
}
.d {
  background-color: #24b2b2;
}

/* Error Style */
@color-primary: #30a9de;
@color-secondary: #30a9de;
@color-tertiary: #30a9de;
@color-primary-light: #6aafe6;
@color-primary-dark: #8ec0e4;
@Distance: 1000px;

.error-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  background: #ffffff;
  min-height: 100vh;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #274c5e;
}

.main {
  justify-content: center;
}

.MainDescription {
  max-width: 50%;
  font-size: 1.2rem;
  font-weight: lighter;
}

.MainGraphic {
  position: relative;
}

.shuttle {
  width: 5rem;
  height: 5rem;
  margin: 10rem;
  transition: easeInOutQuint();
  animation: CogAnimation 30s linear infinite;
}

.astronaut {
  width: 5rem;
  height: 5rem;
  transition: easeInOutQuint();
  animation: CogAnimation2 25s linear infinite;
}

.world {
  width: 10rem;
  height: 10rem;
  margin: 70px 50px;
  transition: easeInOutQuint();
}

@keyframes CogAnimation {
  0% {
    transform: rotate(-360deg);
  }
  100% {
    transform: rotate(0deg);
  }
}

@keyframes CogAnimation2 {
  0% {
    transform: rotate(360deg);
  }
  100% {
    transform: rotate(0deg);
  }
}

.qualification_file {
  height: 133px;
  width: 133px;
  border-radius: 8px;
  object-fit: cover;
}
button.setting_profile_btn.float_right.btn.btn-primary img {
  margin: 0 15px 0 0;
  height: 20px;
  width: 20px;
}

.share-your-sec {
  border: 1px solid #ced4da;
  border-radius: 12px;
  background: none;
  line-height: 1.9;
  width: 100%;
  color: transparent;
  transition: all 0.28s ease;
  box-shadow: none;
  font-family: Mulish;
  font-weight: 600;
  font-size: 16px;
  height: 48px !important;
  color: #1d1929;
  border-radius: 10px;
}

.share-your-sec .form-control {
  border-radius: 12px;
  border: none;
}

.share-your-sec .btn-primary {
  background: transparent;
  color: #3093bb;
  border: none;
}

.share-your-sec .btn-primary img {
  margin-right: 10px;
}
input:disabled {
  color: hsl(0, 0%, 60%);
}
.medicine_card_box .form-group > textarea {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}
.right-text a {
  text-decoration: none;
}
.appoinment_input p.left_text {
  margin: 0 !important;
}
.view-btn {
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0px;
  gap: 10px;
  width: 77px;
  height: 28px;
  background: #ffffff;
  text-decoration: none !important;
  color: #24b2b2;
  border: 1px solid #24b2b2;
  border-radius: 6px;
}
.work_profile_certificate {
  height: 50px;
  width: 100px;
  object-fit: cover;
}
.go_offline {
  color: #f8446c;
}
.empty-data-block {
  display: flex;
  justify-content: center;
  align-items: center;
}
.empty-text {
  background: rgba(228, 75, 65, 0.15);
  margin: 20vh 0;
  padding: 8px 16px;
  border-radius: 8px;
  color: #e44b41;
  font-size: 16px;
  font-weight: 600;
}

.message-content .msg-image {
  max-height: 191px;
  max-width: 198px;
  min-width: 100px;
  min-height: 100px;
  object-fit: cover;
  border-radius: 8px;
}
.img-container {
  padding: 10%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.img-container img {
  height: 500px;
  width: 500px;
  object-fit: cover;
  border-radius: 8px;
}
#chat-message-list .prescription {
  background-color: transparent;
  border: none;
}
.list {
  position: absolute;
  margin: 10px auto;
  border-radius: 8px;
  width: 390px;
  display: flex;
  flex-direction: column;
  background: #f8fbff;
  z-index: 9;
}
.list-container {
  padding: 10px;
  max-height: 330px;
  overflow-y: scroll;
  overflow-x: hidden;
}
.list-item {
  padding: 15px;
  border-bottom: 1px solid #eceff4;
}
.pagination {
  justify-content: center;
  margin-top: 10px;
}
.pagination ul {
  padding: 0;
}
.pagination ul li {
  display: inline-block;
  text-align: center;
  position: relative;
  transition: all 0.5s ease 0s;
  top: 0;
}
.pagination ul li.disable {
  cursor: not-allowed;
}
.pagination ul li.active {
  text-decoration: underline;
}
.pagination ul li + li {
  margin-left: 27px;
}
.pagination ul li a {
  font-weight: 500;
  font-size: 18px;
  line-height: 20px;
  color: #000000;
  text-decoration: none !important;
}

.pagination li.disable a {
  color: #7e7e7e !important;
  pointer-events: none;
}
.in-process-box {
  border-radius: 4px;
  padding: 7px;
  font-family: "Mulish";
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 12px;

  text-align: center;
  margin-top: 23px;
}
.inprocess_tag {
  background: rgba(83, 121, 219, 0.15);
  border-radius: 6px;
  padding: 6px 10px;
  font-family: "Mulish";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
  color: rgba(83, 121, 219, 1);
}

.audio-call-container {
  height: 70vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}
.user-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 80%;
  width: 100%;
}
/* .action-container {
} */
.btn-call-end {
  background: #fa4169;
  border: none;
  border-radius: 8px;
  padding: 8px 10px;
  margin: 0 10px;
}
.btn-call-mute {
  background: #e0e0e0;
  border: none;
  border-radius: 8px;
  padding: 8px 13px 12px 13px;
  margin: 0 10px;
}
.bank-verify {
  padding: 0 10px;
  height: 25px;
  margin: 10px;
}
.signature-canvas {
  width: 100%;
  min-height: 500px;
}
.signature-btn-modal-footer {
  width: 100%;
}

input[type="date"]#dob:required:invalid::-webkit-datetime-edit {
  color: transparent !important;
}
input[type="date"]#dob:focus::-webkit-datetime-edit {
  color: #1d1929 !important;
}
