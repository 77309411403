.time-pickerss {
  display: flex;
  gap: 28px;
  align-items: center;
  justify-self: center !important;
  justify-content: center !important;
}

.un-available {
  font-family: Mulish;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  color: #9393aa;
}

.start-timess {
  width: 100%;
  /* background-color: #ecf2ff; */
  border: none;
  border-radius: 100px;
  padding: 8px 10px;
}

.clock {
  width: 100%;
  height: 10px;
  background: url('../../../../Assets/img/png/light.svg') no-repeat;
  background-size: cover;
  /* or background-size: contain; depending on your image size and preference */
  display: flex;
  align-items: center;
  justify-content: center;
}

.time-day {
  background: none;
  /* Remove any default input background */
  border: none;
  /* Remove borders if needed */
  background-color: #ecf2ff;
  font-size: 10px;
  /* Add any other styles you want for the input */
}

.day-name {
  font-family: Mulish;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  width: 10px;
}

.maindiv_day {
  display: flex;
  gap: 60px;
  align-items: center;
  margin: 15px 0px;
}

/* Apply custom styles for the time picker */

.drop-icon {
  font-size: 12px;
}

input[type="time"] {
  width: 100%;
  height: 48px;
  padding: 10px;
  border: none;
  font-size: 16px;
  background-color: #ecf2ff;
  border-radius: 100px;
}



/* edit slot style star */
.emergency-calls {
  display: flex;
  align-items: center;
  gap: 10px;
}

.eme_label {
  font-family: Mulish;
  font-size: 15px;
  font-style: normal;
  font-weight: 700;
}

.edit_time_slot_mainss {
  display: flex;
  gap: 40px;
  align-items: center;
}

.map_main_divss {
  display: flex;
  margin: 10px 0px;
  flex-direction: column;
  gap: 40px !important;
}

/* end */