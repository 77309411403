/*------------------------------------------Margin Top Start------------------------------------------------*/
.mt_5 {
  margin-top: 5px;
}

.mt_10 {
  margin-top: 10px;
}

.mt_15 {
  margin-top: 15px;
}

.mt_20 {
  margin-top: 20px;
}

.mt_25 {
  margin-top: 25px;
}

.mt_30 {
  margin-top: 30px;
}

.mt_35 {
  margin-top: 35px;
}

.mt_40 {
  margin-top: 40px;
}

.mt_45 {
  margin-top: 45px;
}

.mt_50 {
  margin-top: 50px;
}

.mt_55 {
  margin-top: 55px;
}

.mt_60 {
  margin-top: 60px;
}

.mt_65 {
  margin-top: 65px;
}

.mt_70 {
  margin-top: 70px;
}

.mt_75 {
  margin-top: 75px;
}

.mt_80 {
  margin-top: 80px;
}

.mt_85 {
  margin-top: 85px;
}

.mt_90 {
  margin-top: 90px;
}

.mt_95 {
  margin-top: 95px;
}

.mt_100 {
  margin-top: 100px;
}

.mt_105 {
  margin-top: 105px;
}

.mt_110 {
  margin-top: 110px;
}

.mt_115 {
  margin-top: 115px;
}

.mt_120 {
  margin-top: 120px;
}

.mt_125 {
  margin-top: 125px;
}

.mt_130 {
  margin-top: 130px;
}

.mt_135 {
  margin-top: 135px;
}

.mt_140 {
  margin-top: 140px;
}

.mt_145 {
  margin-top: 145px;
}

.mt_150 {
  margin-top: 150px;
}

.mt_155 {
  margin-top: 155px;
}

.mt_160 {
  margin-top: 160px;
}

.mt_165 {
  margin-top: 165px;
}

.mt_170 {
  margin-top: 170px;
}

.mt_175 {
  margin-top: 175px;
}

.mt_180 {
  margin-top: 180px;
}

.mt_185 {
  margin-top: 185px;
}

.mt_190 {
  margin-top: 190px;
}

.mt_195 {
  margin-top: 195px;
}

.mt_200 {
  margin-top: 200px;
}

.mt_205 {
  margin-top: 205px;
}

.mt_210 {
  margin-top: 210px;
}

.mt_215 {
  margin-top: 215px;
}

.mt_220 {
  margin-top: 220px;
}

.mt_225 {
  margin-top: 225px;
}

.mt_230 {
  margin-top: 230px;
}

.mt_235 {
  margin-top: 235px;
}

.mt_240 {
  margin-top: 240px;
}

.mt_245 {
  margin-top: 245px;
}

.mt_250 {
  margin-top: 250px;
}

.mt_255 {
  margin-top: 255px;
}

.mt_260 {
  margin-top: 260px;
}

.mt_265 {
  margin-top: 265px;
}

.mt_270 {
  margin-top: 270px;
}

.mt_275 {
  margin-top: 275px;
}

.mt_280 {
  margin-top: 280px;
}

.mt_285 {
  margin-top: 285px;
}

.mt_290 {
  margin-top: 290px;
}

.mt_295 {
  margin-top: 295px;
}

.mt_300 {
  margin-top: 300px;
}

.mt_350 {
  margin-top: 350px;
}

.mt_400 {
  margin-top: 400px;
}

.mt_5 {
  margin-top: 5px;
}

.mt_10 {
  margin-top: 10px;
}

.mt_15 {
  margin-top: 15px;
}

.mt_20 {
  margin-top: 20px;
}

.mt_25 {
  margin-top: 25px;
}

.mt_30 {
  margin-top: 30px;
}

.mt_35 {
  margin-top: 35px;
}

.mt_40 {
  margin-top: 40px;
}

.mt_45 {
  margin-top: 45px;
}

.mt_50 {
  margin-top: 50px;
}

.mt_55 {
  margin-top: 55px;
}

.mt_60 {
  margin-top: 60px;
}

.mt_65 {
  margin-top: 65px;
}

.mt_70 {
  margin-top: 70px;
}

.mt_75 {
  margin-top: 75px;
}

.mt_80 {
  margin-top: 80px;
}

.mt_85 {
  margin-top: 85px;
}

.mt_90 {
  margin-top: 90px;
}

.mt_95 {
  margin-top: 95px;
}

.mt_100 {
  margin-top: 100px;
}

.mt_105 {
  margin-top: 105px;
}

.mt_110 {
  margin-top: 110px;
}

.mt_115 {
  margin-top: 115px;
}

.mt_120 {
  margin-top: 120px;
}

.mt_125 {
  margin-top: 125px;
}

.mt_130 {
  margin-top: 130px;
}

.mt_135 {
  margin-top: 135px;
}

.mt_140 {
  margin-top: 140px;
}

.mt_145 {
  margin-top: 145px;
}

.mt_150 {
  margin-top: 150px;
}

.mt_155 {
  margin-top: 155px;
}

.mt_160 {
  margin-top: 160px;
}

.mt_165 {
  margin-top: 165px;
}

.mt_170 {
  margin-top: 170px;
}

.mt_175 {
  margin-top: 175px;
}

.mt_180 {
  margin-top: 180px;
}

.mt_185 {
  margin-top: 185px;
}

.mt_190 {
  margin-top: 190px;
}

.mt_195 {
  margin-top: 195px;
}

.mt_200 {
  margin-top: 200px;
}

.mt_205 {
  margin-top: 205px;
}

.mt_210 {
  margin-top: 210px;
}

.mt_215 {
  margin-top: 215px;
}

.mt_220 {
  margin-top: 220px;
}

.mt_225 {
  margin-top: 225px;
}

.mt_230 {
  margin-top: 230px;
}

.mt_235 {
  margin-top: 235px;
}

.mt_240 {
  margin-top: 240px;
}

.mt_245 {
  margin-top: 245px;
}

.mt_250 {
  margin-top: 250px;
}

.mt_255 {
  margin-top: 255px;
}

.mt_260 {
  margin-top: 260px;
}

.mt_265 {
  margin-top: 265px;
}

.mt_270 {
  margin-top: 270px;
}

.mt_275 {
  margin-top: 275px;
}

.mt_280 {
  margin-top: 280px;
}

.mt_285 {
  margin-top: 285px;
}

.mt_290 {
  margin-top: 290px;
}

.mt_295 {
  margin-top: 295px;
}

.mt_300 {
  margin-top: 300px;
}

.mt_350 {
  margin-top: 350px;
}

.mt_400 {
  margin-top: 400px;
}

/*------------------------------------------Margin Top End--------------------------------------------------*/
/*------------------------------------------Margin Bottom Start------------------------------------------------*/
.mb_5 {
  margin-bottom: 5px;
}

.mb_10 {
  margin-bottom: 10px;
}

.mb_15 {
  margin-bottom: 15px;
}

.mb_20 {
  margin-bottom: 20px;
}

.mb_25 {
  margin-bottom: 25px;
}

.mb_30 {
  margin-bottom: 30px;
}

.mb_35 {
  margin-bottom: 35px;
}

.mb_40 {
  margin-bottom: 40px;
}

.mb_45 {
  margin-bottom: 45px;
}

.mb_50 {
  margin-bottom: 50px;
}

.mb_55 {
  margin-bottom: 55px;
}

.mb_60 {
  margin-bottom: 60px;
}

.mb_65 {
  margin-bottom: 65px;
}

.mb_70 {
  margin-bottom: 70px;
}

.mb_75 {
  margin-bottom: 75px;
}

.mb_80 {
  margin-bottom: 80px;
}

.mb_85 {
  margin-bottom: 85px;
}

.mb_90 {
  margin-bottom: 90px;
}

.mb_95 {
  margin-bottom: 95px;
}

.mb_100 {
  margin-bottom: 100px;
}

/*------------------------------------------Margin Bottom End--------------------------------------------------*/
/*----------------------------------------------Margin Left Start------------------------------------------------------*/
.ml_0 {
  margin-left: 0px;
}

.ml_5 {
  margin-left: 5px;
}

.ml_10 {
  margin-left: 10px;
}

.ml_15 {
  margin-left: 15px;
}

.ml_20 {
  margin-left: 20px;
}

.ml_25 {
  margin-left: 25px;
}

.ml_30 {
  margin-left: 30px;
}

.ml_35 {
  margin-left: 35px;
}

.ml_40 {
  margin-left: 40px;
}

.ml_45 {
  margin-left: 45px;
}

.ml_50 {
  margin-left: 50px;
}

.ml_55 {
  margin-left: 55px;
}

.ml_60 {
  margin-left: 60px;
}

.ml_65 {
  margin-left: 65px;
}

.ml_70 {
  margin-left: 70px;
}

.ml_75 {
  margin-left: 75px;
}

.ml_80 {
  margin-left: 80px;
}

.ml_85 {
  margin-left: 85px;
}

.ml_90 {
  margin-left: 90px;
}

.ml_95 {
  margin-left: 95px;
}

.ml_100 {
  margin-left: 100px;
}

/*----------------------------------------------Margin Left end--------------------------------------------------------*/
/*----------------------------------------------Margin right Start------------------------------------------------------*/
.mr_5 {
  margin-right: 5px;
}

.mr_10 {
  margin-right: 10px;
}

.mr_15 {
  margin-right: 15px;
}

.mr_20 {
  margin-right: 10px;
}

.mr_25 {
  margin-right: 25px;
}

.mr_30 {
  margin-right: 30px;
}

.mr_35 {
  margin-right: 35px;
}

.mr_40 {
  margin-right: 40px;
}

.mr_45 {
  margin-right: 45px;
}

.mr_50 {
  margin-right: 50px;
}

.mr_55 {
  margin-right: 55px;
}

.mr_60 {
  margin-right: 60px;
}

.mr_65 {
  margin-right: 65px;
}

.mr_70 {
  margin-right: 70px;
}

.mr_75 {
  margin-right: 75px;
}

.mr_80 {
  margin-right: 80px;
}

.mr_85 {
  margin-right: 85px;
}

.mr_90 {
  margin-right: 90px;
}

.mr_95 {
  margin-right: 95px;
}

.mr_100 {
  margin-right: 100px;
}

/*----------------------------------------------Margin right end--------------------------------------------------------*/
/*------------------------------------------Padding Top Start------------------------------------------------*/
.pt_5 {
  padding-top: 5px;
}

.pt_10 {
  padding-top: 10px;
}

.pt_15 {
  padding-top: 15px;
}

.pt_20 {
  padding-top: 20px;
}

.pt_25 {
  padding-top: 25px;
}

.pt_30 {
  padding-top: 30px;
}

.pt_35 {
  padding-top: 35px;
}

.pt_40 {
  padding-top: 40px;
}

.pt_45 {
  padding-top: 45px;
}

.pt_50 {
  padding-top: 50px;
}

.pt_55 {
  padding-top: 55px;
}

.pt_60 {
  padding-top: 60px;
}

.pt_65 {
  padding-top: 65px;
}

.pt_70 {
  padding-top: 70px;
}

.pt_75 {
  padding-top: 75px;
}

.pt_80 {
  padding-top: 80px;
}

.pt_85 {
  padding-top: 85px;
}

.pt_90 {
  padding-top: 90px;
}

.pt_95 {
  padding-top: 95px;
}

.pt_100 {
  padding-top: 100px;
}

.pt_105 {
  padding-top: 105px;
}

.pt_110 {
  padding-top: 110px;
}

.pt_115 {
  padding-top: 115px;
}

.pt_120 {
  padding-top: 120px;
}

.pt_125 {
  padding-top: 125px;
}

.pt_130 {
  padding-top: 130px;
}

.pt_135 {
  padding-top: 135px;
}

.pt_140 {
  padding-top: 140px;
}

.pt_145 {
  padding-top: 145px;
}

.pt_150 {
  padding-top: 150px;
}

.pt_155 {
  padding-top: 155px;
}

.pt_160 {
  padding-top: 160px;
}

.pt_165 {
  padding-top: 165px;
}

.pt_170 {
  padding-top: 170px;
}

.pt_175 {
  padding-top: 175px;
}

.pt_180 {
  padding-top: 180px;
}

.pt_185 {
  padding-top: 185px;
}

.pt_190 {
  padding-top: 190px;
}

.pt_195 {
  padding-top: 195px;
}

.pt_200 {
  padding-top: 200px;
}

/*------------------------------------------padding Top End--------------------------------------------------*/
.mb_5 {
  margin-bottom: 5px;
}

.mb_10 {
  margin-bottom: 10px;
}

.mb_15 {
  margin-bottom: 15px;
}

.mb_20 {
  margin-bottom: 20px;
}

.mb_25 {
  margin-bottom: 25px;
}

.mb_30 {
  margin-bottom: 30px;
}

.mb_35 {
  margin-bottom: 35px;
}

.mb_40 {
  margin-bottom: 40px;
}

.mb_45 {
  margin-bottom: 45px;
}

.mb_50 {
  margin-bottom: 50px;
}

.mb_55 {
  margin-bottom: 55px;
}

.mb_60 {
  margin-bottom: 60px;
}

.mb_65 {
  margin-bottom: 65px;
}

.mb_70 {
  margin-bottom: 70px;
}

.mb_75 {
  margin-bottom: 75px;
}

.mb_80 {
  margin-bottom: 80px;
}

.mb_85 {
  margin-bottom: 85px;
}

.mb_90 {
  margin-bottom: 90px;
}

.mb_95 {
  margin-bottom: 95px;
}

.mb_100 {
  margin-bottom: 100px;
}

/*------------------------------------------Margin Bottom End--------------------------------------------------*/
/*----------------------------------------------Margin Left Start------------------------------------------------------*/
.ml_0 {
  margin-left: 0px;
}

.ml_5 {
  margin-left: 5px;
}

.ml_10 {
  margin-left: 10px;
}

.ml_15 {
  margin-left: 15px;
}

.ml_20 {
  margin-left: 20px;
}

.ml_25 {
  margin-left: 25px;
}

.ml_30 {
  margin-left: 30px;
}

.ml_35 {
  margin-left: 35px;
}

.ml_40 {
  margin-left: 40px;
}

.ml_45 {
  margin-left: 45px;
}

.ml_50 {
  margin-left: 50px;
}

.ml_55 {
  margin-left: 55px;
}

.ml_60 {
  margin-left: 60px;
}

.ml_65 {
  margin-left: 65px;
}

.ml_70 {
  margin-left: 70px;
}

.ml_75 {
  margin-left: 75px;
}

.ml_80 {
  margin-left: 80px;
}

.ml_85 {
  margin-left: 85px;
}

.ml_90 {
  margin-left: 90px;
}

.ml_95 {
  margin-left: 95px;
}

.ml_100 {
  margin-left: 100px;
}

/*----------------------------------------------Margin Left end--------------------------------------------------------*/
/*----------------------------------------------Margin right Start------------------------------------------------------*/
.mr_5 {
  margin-right: 5px;
}

.mr_10 {
  margin-right: 10px;
}

.mr_15 {
  margin-right: 15px;
}

.mr_20 {
  margin-right: 10px;
}

.mr_25 {
  margin-right: 25px;
}

.mr_30 {
  margin-right: 30px;
}

.mr_35 {
  margin-right: 35px;
}

.mr_40 {
  margin-right: 40px;
}

.mr_45 {
  margin-right: 45px;
}

.mr_50 {
  margin-right: 50px;
}

.mr_55 {
  margin-right: 55px;
}

.mr_60 {
  margin-right: 60px;
}

.mr_65 {
  margin-right: 65px;
}

.mr_70 {
  margin-right: 70px;
}

.mr_75 {
  margin-right: 75px;
}

.mr_80 {
  margin-right: 80px;
}

.mr_85 {
  margin-right: 85px;
}

.mr_90 {
  margin-right: 90px;
}

.mr_95 {
  margin-right: 95px;
}

.mr_100 {
  margin-right: 100px;
}

/*----------------------------------------------Margin right end--------------------------------------------------------*/
/*------------------------------------------Padding Top Start------------------------------------------------*/
.pt_5 {
  padding-top: 5px;
}

.pt_10 {
  padding-top: 10px;
}

.pt_15 {
  padding-top: 15px;
}

.pt_20 {
  padding-top: 20px;
}

.pt_25 {
  padding-top: 25px;
}

.pt_30 {
  padding-top: 30px;
}

.pt_35 {
  padding-top: 35px;
}

.pt_40 {
  padding-top: 40px;
}

.pt_45 {
  padding-top: 45px;
}

.pt_50 {
  padding-top: 50px;
}

.pt_55 {
  padding-top: 55px;
}

.pt_60 {
  padding-top: 60px;
}

.pt_65 {
  padding-top: 65px;
}

.pt_70 {
  padding-top: 70px;
}

.pt_75 {
  padding-top: 75px;
}

.pt_80 {
  padding-top: 80px;
}

.pt_85 {
  padding-top: 85px;
}

.pt_90 {
  padding-top: 90px;
}

.pt_95 {
  padding-top: 95px;
}

.pt_100 {
  padding-top: 100px;
}

.pt_105 {
  padding-top: 105px;
}

.pt_110 {
  padding-top: 110px;
}

.pt_115 {
  padding-top: 115px;
}

.pt_120 {
  padding-top: 120px;
}

.pt_125 {
  padding-top: 125px;
}

.pt_130 {
  padding-top: 130px;
}

.pt_135 {
  padding-top: 135px;
}

.pt_140 {
  padding-top: 140px;
}

.pt_145 {
  padding-top: 145px;
}

.pt_150 {
  padding-top: 150px;
}

.pt_155 {
  padding-top: 155px;
}

.pt_160 {
  padding-top: 160px;
}

.pt_165 {
  padding-top: 165px;
}

.pt_170 {
  padding-top: 170px;
}

.pt_175 {
  padding-top: 175px;
}

.pt_180 {
  padding-top: 180px;
}

.pt_185 {
  padding-top: 185px;
}

.pt_190 {
  padding-top: 190px;
}

.pt_195 {
  padding-top: 195px;
}

.pt_200 {
  padding-top: 200px;
}

/*------------------------------------------padding Top End--------------------------------------------------*/
/*------------------------------------------Padding Top Start------------------------------------------------*/
.pl_5 {
  padding-left: 5px;
}

.pl_10 {
  padding-left: 10px;
}

.pl_15 {
  padding-left: 15px;
}

.pl_20 {
  padding-left: 20px;
}

.pl_25 {
  padding-left: 25px;
}

.pl_30 {
  padding-left: 30px;
}

.pl_35 {
  padding-left: 35px;
}

.pl_40 {
  padding-left: 40px;
}

.pl_45 {
  padding-left: 45px;
}

.pl_50 {
  padding-left: 50px;
}

.pl_55 {
  padding-left: 55px;
}

.pl_60 {
  padding-left: 60px;
}

.pl_65 {
  padding-left: 65px;
}

.pl_70 {
  padding-left: 70px;
}

.pl_75 {
  padding-left: 75px;
}

.pl_80 {
  padding-left: 80px;
}

.pl_85 {
  padding-left: 85px;
}

.pl_90 {
  padding-left: 90px;
}

.pl_95 {
  padding-left: 95px;
}

.pl_100 {
  padding-left: 100px;
}

.pl_105 {
  padding-left: 105px;
}

.pl_110 {
  padding-left: 110px;
}

.pl_115 {
  padding-left: 115px;
}

.pl_120 {
  padding-left: 120px;
}

.pl_125 {
  padding-left: 125px;
}

.pl_130 {
  padding-left: 130px;
}

.pl_135 {
  padding-left: 135px;
}

.pl_140 {
  padding-left: 140px;
}

.pl_145 {
  padding-left: 145px;
}

.pl_150 {
  padding-left: 150px;
}

.pl_155 {
  padding-left: 155px;
}

.pl_160 {
  padding-left: 160px;
}

.pl_165 {
  padding-left: 165px;
}

.pl_170 {
  padding-left: 170px;
}

.pt_175 {
  padding-top: 175px;
}

.pt_18 {
  padding-left: 180px;
}

.pl_185 {
  padding-left: 185px;
}

.pl_190 {
  padding-left: 190px;
}

.pl_195 {
  padding-left: 195px;
}

.pl_200 {
  padding-left: 200px;
}

/*------------------------------------------padding Top End--------------------------------------------------*/
.display_inline {
  display: inline-flex;
}

.display_none {
  display: none;
}

.width_100 {
  width: 100%;
}

form {
  width: 100%;
}

.body-form {
  display: inline-grid;
  align-items: center;
  gap: 10px;
  justify-content: space-evenly;
}

.button-form {
  margin: 10px;
  background: rgb(165 42 42);
  border: 0;
  padding: 10px 25px;
  border-radius: 4px;
  color: rgb(241 241 241);
}

.input-form {
  padding: 5px 10px;
  margin: 10px;
}

.section_1_bg {
  position: relative;
  overflow-x: hidden;
  width: 100%;
  height: 100%;
  -webkit-transition: 0.5s;
  -o-transition: 0.5s;
  transition: 0.5s;
}

.section_1_container {
  width: 100%;
  float: right;
  flex-wrap: wrap;
  -webkit-flex-wrap: wrap;
  -moz-flex-wrap: wrap;
  display: inline-block;
  display: -moz-box;
  display: -ms-flexbox;
}

.sub_section_1 {
  /*background-image: url('img/sideimage.png');*/
  background: #fff;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  position: relative;
  width: 50%;
  float: left;
}

.sub_section_2 {
  width: 50%;
  float: right;
  background: #f8fbff;
  /* border-radius: 60px 0px 0px 60px; */
  height: 100vh;
}

.display_t {
  display: block;
  min-height: 700px;
  margin: 0px 30px 0px 30px;

}

.create_account {
  width: 148px;
  height: 48px;
  background: #0d46fb;
  border-radius: 16px;
  padding: 10px 20px;
  color: #fff;
  font-family: Mulish;
  font-size: 14px;
  font-weight: 700;
  border: none;
}

.float_right {
  float: right;
}

.float_left {
  float: left;
}

.logo_title {
  font-family: Mulish;
  font-weight: 800;
  font-size: 35px;
  color: #1d1929;
  text-align: left;
}

.modal_150 {
  width: 150px;
}

.modal_300 {
  width: 300px;
}

.modal_350 {
  width: 350px;
}

.modal_500 {
  width: 500px;
}

/*------------------------------------------Login Form Start------------------------------------*/
.signin_box {
  padding: 50px 0;
}

.sign_title {
  font-weight: 800;
  font-size: 35px;
  color: #1d1929;
  text-align: left;
}

.login_box {
  padding: 100px 70px;
}

.basic_info_box {
  padding: 75px 140px;
}

.basic_info_form_box {
  padding: 30px 0;
}

.welcome_title {
  font-family: Mulish;
  font-weight: 600;
  font-size: 18px;
  margin-top: 15px;
  color: #1d1929;
  text-align: left;
}

/*------------------------------------------Login Form Start------------------------------------*/
.signin_box {
  padding: 30px 0;
}

.sign_title {
  font-family: Mulish;
  font-size: 16px;
  color: #000;
  line-height: 20px;
  font-weight: 600;
}

.logo_box {
  padding: 50px 20px;
}

.slider_1 {
  padding: 70px 80px 40px 80px;
}

.slider_text {
  font-family: Mulish;
  font-weight: 800;
  font-size: 24px;
  color: #1e1f20;
  line-height: 36px;
  padding-top: 20px !important;
}

.by_clicking {
  font-family: Mulish;
  font-weight: 600;
  font-size: 16px;
  color: #000;
  line-height: 36px;
  text-align: center;
}

.login_with_otp {
  font-family: Mulish;
  font-size: 14px;
  color: #3093bb;
  font-weight: 600;
  cursor: pointer;
}

.forgot_password {
  font-family: Mulish;
  font-size: 14px;
  color: #3093bb;
  margin-top: 0px;
  font-weight: 600;
  cursor: pointer;
}

.dont_have_account {
  font-family: "Mulish";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
}

.sign_up {
  font-family: "Mulish";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  color: #f8446c;
  text-decoration: none;
}

.input_box {
  /* border: 1px solid #ede8e9; */
  height: 48px;
  padding: 0px 0 0 0px;
  border-radius: 10px;
}

.forgot_text {
  font-family: Mulish;
  font-weight: 400;
  font-size: 18px;
  float: right;
}

.forgot_text a.ahref {
  font-family: Mulish;
  font-weight: 400;
  font-size: 18px;
  float: right;
  text-decoration: none;
  color: #1d1929;
}

.login_btn {
  font-family: Mulish;
  font-weight: 700;
  font-size: 16px;
  background: linear-gradient(45deg, #3093bb 0%, #82dbff 100%);
  width: 100%;
  padding: 15px 20px;
  border: none;
  border-radius: 12px;
  color: #fff;
  margin-top: 50px;
}

.dont_account {
  font-family: Mulish;
  font-weight: 700;
  font-size: 16px;
  color: #77757f;
  margin-top: 50px;
}

.new_account {
  font-family: Mulish;
  font-weight: 700;
  font-size: 16px;
  color: #fd6e22;
}

.new_account a.ahref {
  font-family: Mulish;
  font-weight: 700;
  font-size: 16px;
  color: #fd6e22;
  text-decoration: none;
}

.ahref {
  text-decoration: none;
}

.lable_text {
  font-family: Mulish;
  position: absolute;
  left: 15px;
  top: 28px;
  display: block;
  margin: 0;
  font-weight: 300;
  z-index: 1;
  color: #908e8e;
  font-size: 14px;
  line-height: 10px;
}

label.field {
  border-radius: 2px;
  color: #666;
  display: block;
  margin: 0px 0 0 7px;
  padding: 8px;
  opacity: 0;
  position: relative;
  transition-property: opacity;
  z-index: 1;
}

label.field span {
  color: inherit;
  display: block;
  font-size: 14px;
  font-weight: 300;
  height: 20px;
  line-height: 20px;
  left: 9px;
  pointer-events: none;
  position: absolute;
  top: 23px;
  transform: scale(1) translateY(0);
  transition-property: color, font-size, top;
  z-index: 1;
}

label.field span.required::after {
  color: inherit;
  content: "*";
  display: block;
  height: 20px;
  left: -20px;
  line-height: 20px;
  position: absolute;
  text-align: center;
  top: 0;
  width: 20px;
}

.error label.field span {
  color: #f02318;
}

label.field .psuedo_select {
  background: #ffffff00;
  position: relative;
  color: #666;
  cursor: pointer;
  font-size: 20px;
  height: 24px;
  line-height: 24px;
  margin: 24px 32px 0 0;
  min-width: 100%;
  /*min-width: 325px;*/
  padding-top: 24px;
  outline: 0;
  z-index: 1;
}

label.field .psuedo_select::after {
  background: url("data:image/svg+xml;utf8,<svg fill='#666' height='24' viewBox='0 0 24 24' width='24' xmlns='http://www.w3.org/2000/svg'> <path d='M7.41 7.84L12 12.42l4.59-4.58L18 9.25l-6 6-6-6z'/> <path d='M0-.75h24v24H0z' fill='none'/> </svg>"),
    no-repeat;
  content: "";
  height: 24px;
  width: 24px;
  position: absolute;
  top: 0;
  right: 0;
  transition-property: background;
}

label.field .psuedo_select .selected {
  font-family: Mulish;
  color: #000;
  font-weight: 600;
  height: 24px;
  left: 1px;
  line-height: 24px;
  opacity: 0;
  position: absolute;
  top: -2px;
  transform: translateY(24px);
  transition-property: opacity, transform;
  will-change: transform;
}

label.field .psuedo_select ul {
  background: #fbf3f3;
  /* box-shadow: 0 1px 4px 0 rgb(0 0 0 / 14%); */
  display: block;
  margin-left: -10px;
  height: 100px !important;
  width: 100%;
  list-style: none;
  margin-top: 2px;
  opacity: 0;
  overflow: hidden;
  padding: 5px 5px;
  pointer-events: none;
  transition-property: height, opacity;
  z-index: 2;
}

label.field .psuedo_select ul li {
  font-family: Mulish;
  font-size: 16px;
  font-weight: 500;
  height: 32px;
  padding: 8px 4px;
  text-align: left;
}

label.field .deselect {
  height: 100vh;
  left: 0;
  position: fixed;
  top: 0;
  width: 100vw;
  z-index: -1;
}

label.field.focused {
  color: #007bed;
}

label.field.focused .psuedo_select {
  border-color: #007bed;
  color: #007bed;
}

label.field.focused .psuedo_select {
  border-color: #007bed;
}

label.field.focused .psuedo_select ul {
  opacity: 1;
  pointer-events: all;
}

.arrow_icon {
  position: absolute;
  top: 32px;
  right: 25px;
  color: #908e8e;
}

.dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  /*content: "";*/
  border-top: 0.3em solid;
  border-right: 0.3em solid transparent;
  border-bottom: 0;
  border-left: 0.3em solid transparent;
}

input:valid~label,
textarea:valid~label,
input:focus~label,
textarea:focus~label {
  font-size: 0.75em;
  top: -1rem;
}

.styled-input {
  background-color: #ffffffcc;
  width: 100%;
  border-radius: 8px;
  position: relative;
  margin-bottom: 20px;
  position: relative;
  font-weight: 300;
  border: solid 1px #ede8e9;
  height: 66px;
}

.styled-input label {
  color: #999;
  padding: 23px 1px;
  position: absolute;
  top: 0;
  left: 0;
  transition: all 0.25s cubic-bezier(0.2, 0, 0.03, 1);
  pointer-events: none;
  font-family: Mulish;
  font-size: 14px;
  font-weight: 300;
  left: 15px;
}

input.input_field,
textarea.input_textarea {
  padding: 2.3rem 1rem 1rem 2rem;
  border: none;
  border-radius: 10px;
  width: 100%;
  font-size: 14px;
  height: 61px;
  font-family: Mulish;
  font-weight: 600;
  /* position: relative; */
  z-index: 2;
  font-size: 15px;
  color: #333;
}

input[type="text"]:focus {
  border-color: #66afe9 !important;
}

input[type="email"]:focus {
  border-color: #66afe9 !important;
}

input[type="email"]:focus {
  border-color: #66afe9 !important;
}

input.input_field:pattern {
  padding: 2.3rem 1rem 1rem 1.7rem;
  border: none;
  border-radius: 10px;
  width: 100%;
  font-size: 14px;
  height: 64px;
  font-family: Mulish;
  font-weight: 600;
  /* position: relative; */
  z-index: 2;
  font-size: 15px;
  color: #333;
}

.form-radio,
.form_group {
  position: relative;
  margin-bottom: 10px;
}

.form-inline>.form_group,
.form-inline>.btn {
  display: inline-block;
  margin-bottom: 0;
}

.form_group input {
  height: 6.2rem;
}

.form_group textarea {
  resize: none;
}

.form_group select {
  width: 100%;
  font-size: 1rem;
  height: 1.6rem;
  padding: 0.125rem 0.125rem 0.0625rem;
  background: none;
  border: none;
  line-height: 1.6;
  box-shadow: none;
}

.form_group .control-label {
  position: absolute;
  top: 2.1rem;
  pointer-events: none;
  padding-left: 2rem !important;
  z-index: 1;
  color: #999;
  transition: all 0.28s ease;
  font-family: Mulish;
  font-size: 16px;
  font-weight: 500;
}

.form_group input,
.form_group textarea {
  display: block;
  background: none;
  padding: 0.5rem 1.5rem 0.5rem 20px !important;
  border-width: 0;
  border-color: transparent;
  line-height: 1.9;
  width: 100%;
  color: transparent;
  -webkit-transition: all 0.28s ease;
  transition: all 0.28s ease;
  box-shadow: none;
  font-family: Mulish;
  font-weight: 600;
  font-size: 16px;
  height: 48px !important;
  color: #1d1929;
  border-radius: 10px;
}

.form_group select:focus,
.form_group input:focus,
.form_group textarea:focus {
  outline: none;
  /*border-radius: 10px;*/
}

.form_group select:focus~.control-label,
.form_group input:focus~.control-label,
.form_group textarea:focus~.control-label {
  color: #337ab7;
}

.form_group select,
.form_group input:focus,
.form_group input[data-value]:not([data-value=""]),
/*.form_group input:valid,*/
.form_group input.form-file,
.form_group input.has-value,
.form_group textarea:focus,
.form_group textarea:valid,
.form_group textarea.form-file,
.form_group textarea.has-value {
  color: #333;
  height: 50px;
  padding-top: 16px;
}

.form_group select~.control-label,
.form_group input:focus~.control-label,
.form_group input[data-value]:not([data-value=""])~.control-label,
/*.form_group input:valid ~ .control-label,*/
.form_group input.form-file~.control-label,
.form_group input.has-value~.control-label,
.form_group textarea:focus~.control-label,
.form_group textarea.form-file~.control-label,
.form_group textarea.has-value~.control-label {
  font-size: 1.2rem !important;
  color: gray;
  top: 1.2rem !important;
  left: 0;
}

input:valid~label,
textarea:valid~label,
input:focus~label,
textarea:focus~label {
  font-size: 1.2rem !important;
  color: gray;
  top: 1.2rem !important;
  left: 0;
}

input:valid~label,
textarea:valid~label,
input:focus~label,
textarea:focus~label {
  font-size: 0.75em;
  top: -0.4rem;
}

.toggle-password {
  float: right;
  cursor: pointer;
  margin-right: 10px;
  margin-top: -34px;
  font-size: 18px;
}

.toggle_password_1 {
  float: right;
  cursor: pointer;
  margin-right: 10px;
  margin-top: -34px;
  font-size: 18px;
}

.login_mobile_verification {
  font-family: "Mulish";
  font-size: 18px;
  line-height: 32px;
  font-weight: 700;
}

.forgot_password_title {
  font-family: "Mulish";
  font-size: 18px;
  line-height: 32px;
  font-weight: 700;
}

.forgot_password_subtext {
  font-family: "Mulish";
  font-weight: 600;
  font-size: 15px;
  line-height: 24px;
  margin-bottom: 20px;
}

.otp_verification_title {
  font-family: "Mulish";
  font-size: 18px;
  line-height: 32px;
  font-weight: 700;
}

.reset_password {
  font-family: "Mulish";
  font-size: 18px;
  line-height: 32px;
  font-weight: 700;
}

.reset_password_subtext {
  font-family: "Mulish";
  font-weight: 600;
  font-size: 15px;
  line-height: 24px;
  margin-bottom: 20px;
}

/*-------------------------------------------Login Form End-------------------------------------*/
/*-------------------------------------------Slider Css Start--------------------------------------*/
.owl-theme .owl-dots .owl-dot span {
  width: 15px !important;
  height: 5px !important;
  margin: 5px 7px;
  background: #d6d6d6;
  display: block;
  -webkit-backface-visibility: visible;
  transition: opacity 200ms ease;
  border-radius: 30px;
}

.owl-theme .owl-dots .owl-dot.active span,
.owl-theme .owl-dots .owl-dot:hover span {
  background: #3093bb !important;
}

/*--------------------------------------------Slider Css End---------------------------------------*/
/*-------------------------------------------OTP Css Start-------------------------------------------*/
.owl-theme .owl-dots .owl-dot.active span,
.owl-theme .owl-dots .owl-dot:hover span {
  background: #3093bb !important;
}

/*--------------------------------------------Slider Css End---------------------------------------*/
/*-------------------------------------------OTP Css Start-------------------------------------------*/
.mobile_verification {
  font-family: "Mulish";
  font-size: 18px;
  line-height: 32px;
  font-weight: 700;
}

.otp_sent {
  font-family: "Mulish";
  font-weight: 600;
  font-size: 15px;
  line-height: 24px;
}

.close_btn {
  font-family: "Mulish";
  font-weight: 600;
  font-size: 15px;
  background: #ffffff;
  border: 1px solid #e0e0e0;
  border-radius: 12px;
  color: #9393aa;
  padding: 10px 40px;
  margin-right: 10px;
  /*width: 50%;*/
}

.verify_btn {
  font-family: "Mulish";
  font-weight: 600;
  font-size: 15px;
  background: linear-gradient(45deg, #3093bb 0%, #82dbff 100%);
  border: 1px solid #e0e0e0;
  border-radius: 12px;
  color: #fff;
  padding: 10px 40px;
  /*width: 50%;*/
}

.verify_btn:hover {
  text-decoration: none;
}

.resend {
  font-family: "Mulish";
  font-weight: 600;
  font-size: 15px;
  line-height: 24px;
  color: #f8446c;
}

.my_work_profile_btn {
  font-family: "Mulish";
  font-weight: 600;
  font-size: 15px;
  background: linear-gradient(45deg, #3093bb 0%, #82dbff 100%);
  border: 1px solid #e0e0e0;
  border-radius: 12px;
  color: #fff;
  padding: 10px 49px;
  width: 100%;
}

.please_fill_out_profile {
  font-family: "Mulish";
  font-weight: 600;
  font-size: 15px;
  line-height: 24px;
  color: #f8446c;
}

.welcome_ths {
  font-family: Mulish;
  font-weight: 600;
  font-size: 18px;
  margin-top: 15px;
  color: #1d1929;
}

.modal_width {
  width: 380px !important;
}

.otpContainer {
  display: inline-flex;
}

.otpInput {
  width: 40px;
  margin-right: 10px;
  height: 46px;
  border-radius: 8px;
  border: 1px solid #f0f0f0;
  margin-bottom: 20px;
  margin-top: 10px;
  padding: 10px;
  text-align: center;
  font-family: Mulish;
}

input:focus {
  border: 1px solid;
  outline: none !important;
  border-color: #719ece;
}

.sendotp_btn {
  font-family: "Mulish";
  font-weight: 600;
  font-size: 15px;
  background: linear-gradient(45deg, #3093bb 0%, #82dbff 100%);
  border: 1px solid #e0e0e0;
  border-radius: 12px;
  color: #fff;
  padding: 10px 25px;
  /*width: 50%;*/
}

.sendotp_btn:hover {
  text-decoration: none;
}

.save_btn {
  font-family: "Mulish";
  font-weight: 600;
  font-size: 15px;
  background: linear-gradient(45deg, #3093bb 0%, #82dbff 100%);
  border: 1px solid #e0e0e0;
  border-radius: 12px;
  color: #fff;
  padding: 10px 70px;
  /*width: 50%;*/
}

.save_btn:hover {
  text-decoration: none;
}

.otp_verify_btn {
  font-family: "Mulish";
  font-weight: 600;
  font-size: 15px;
  background: linear-gradient(45deg, #3093bb 0%, #82dbff 100%);
  border: 1px solid #e0e0e0;
  border-radius: 12px;
  color: #fff;
  padding: 10px 40px;
  /* width: 50%; */
}

.password_reset_success_title {
  font-family: Mulish;
  font-weight: 600;
  font-size: 18px;
  margin-top: 15px;
  color: #1d1929;
  margin-top: 30px;
}

.password_reset_success_subtitle {
  font-family: "Mulish";
  font-weight: 600;
  font-size: 15px;
  line-height: 24px;
  color: #f8446c;
}

.go_to_login {
  font-family: "Mulish";
  font-weight: 600;
  font-size: 15px;
  background: linear-gradient(45deg, #3093bb 0%, #82dbff 100%);
  border: 1px solid #e0e0e0;
  border-radius: 12px;
  color: #fff;
  padding: 10px 89px;
  width: 100%;
}

/*--------------------------------------------OTP Css End--------------------------------------------*/
/*--------------------------------------------User Profile Css Start--------------------------------------------*/
.basic_box {
  padding: 50px 0;
}

.steps {
  font-family: Mulish;
  font-size: 15px;
  font-weight: 600;
  color: #1e1f20;
}

.info_title {
  font-family: Mulish;
  font-size: 24px;
  font-weight: 800;
  color: #1e1f20;
  margin-top: 15px;
  margin-bottom: 20px;
}

.info_subtitle {
  font-family: Mulish;
  font-size: 16px;
  font-weight: 500;
  line-height: 22px;
}

.upload_avatar_btn {
  font-family: Mulish;
  font-size: 16px;
  font-weight: 600;
  line-height: 16px;
  border: none;
  background: #24b2b2;
  border-radius: 10px;
  width: 200px;
  padding: 15px 10px;
  color: #fff;
}

.upload_avatar_btn:hover {
  background: #24b2b2;
  color: #fff;
}

.upload_avatar_subtitle {
  font-family: "Mulish";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  color: #9393aa;
  margin-top: 10px;
}

.progress_box {
  border: 1px dashed #24b2b2;
  border-radius: 10px;
  padding: 2px 0px 2px 15px;
  width: -webkit-fill-available;
}

.profile_milestone {
  font-family: "Mulish";
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  margin-bottom: 0;
  margin-top: 13px;
}

.progress_bar_subtext {
  font-family: "Mulish";
  font-style: normal;
  font-weight: 500;
  font-size: 11px;
  line-height: 12px;
  color: #9393aa;
  margin-bottom: 13px;
}

.progress-outer {
  display: flex;
  align-items: center;
  margin-bottom: -10px;
  margin-top: -5px;
}

.progress-info {
  width: 30px;
  padding-left: 20px;
  font-family: "Mulish";
  font-weight: 600;
  font-size: 20px;
  color: #f8446c;
  margin-top: 10px;
  padding-top: 5px;
}

.progress {
  width: 100%;
  background: #eee;
  min-height: 1px;
  position: relative;
  overflow: hidden;
  border-radius: 10px;
}

.progress.progress--small {
  height: 3px;
}

.progress.progress--medium {
  height: 5px;
}

.progress.progress--large {
  height: 10px;
}

.progress-bar {
  background: #24b2b2;
  height: 100%;
  position: absolute;
  left: 0;
}

.progress.progress--loading .progress-bar {
  width: 30%;
  animation: moveToRight 1s infinite linear;
  border-radius: 3%;
}

.skip {
  font-family: "Mulish";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
  float: right;
  color: #1e1f20;
  cursor: pointer;
}

.wrapper {
  display: inline-block;
  position: relative;
}

.wrapper {
  display: inline-block;
  position: relative;
}

.form-select {
  background: none;
  padding: 0.5rem 1.5rem 0.5rem 20px !important;
  border-width: 0;
  line-height: 1.9;
  width: 100%;
  color: transparent;
  transition: all 0.28s ease;
  box-shadow: none;
  font-family: Mulish;
  font-weight: 600;
  font-size: 16px;
  height: 48px !important;
  color: #1d1929;
  border: 1px solid #ede8e9;
  border-radius: 10px;
  background-color: #fff;
  background-image: url("../img/png/drop_arrow.png");
  background-repeat: no-repeat;
  background-position: right 0.75rem center;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  display: block;
}

.date-filter.form-select {
  padding: 0px 20px !important;
  max-height: 40px;
}

.gender_wrapper {
  display: inline-block;
  position: relative;
}

.checkbox_input input[type="radio"] {
  opacity: 1 !important;
}

input[type="radio"] {
  opacity: 0;
  /*position:absolute;*/
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.gender_wrapper {
  display: inline-block;
  position: relative;
}

input[type="radio"] {
  opacity: 0;
  /*position:absolute;*/
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.male_icon {
  /*background-image: url('../img/png/ic_male.svg');*/
  background-repeat: no-repeat;
  height: 65px;
  width: 65px;
  padding: 30px;
  background-position: center center;
  box-shadow: 0px 10px 20px rgb(29 161 242 / 30%);
  border: 1px solid #f0f0f0;
  border-radius: 20px;
  --bs-btn-bg: #fff;
}

.male_icon:hover,
.fill_svg:hover {
  --bs-btn-hover-bg: #24b2b2 !important;
  --bs-btn-active-bg: #24b2b2 !important;
  --bs-btn-active-shadow: none !important;
  --bs-btn-border-color: #24b2b2 !important;
  --bs-btn-hover-color: #24b2b2 !important;
  --bs-btn-hover-border-color: #24b2b2 !important;
  --bs-btn-focus-shadow-rgb: #24b2b2 !important;
  --bs-btn-active-color: #24b2b2 !important;
  --bs-btn-active-border-color: #24b2b2 !important;
  --bs-btn-disabled-color: #24b2b2 !important;
  --bs-btn-disabled-bg: #24b2b2 !important;
  --bs-btn-disabled-border-color: #24b2b2 !important;
  border: none;
}

.female_icon {
  border: 1px solid #f0f0f0;
  border-radius: 20px;
  --bs-btn-bg: #fff;
}

.male_icon:hover,
.fill_svg:hover {
  --bs-btn-hover-bg: #24b2b2 !important;
  --bs-btn-active-bg: #24b2b2 !important;
  --bs-btn-active-shadow: none !important;
  --bs-btn-border-color: #24b2b2 !important;
  --bs-btn-hover-color: #24b2b2 !important;
  --bs-btn-hover-border-color: #24b2b2 !important;
  --bs-btn-focus-shadow-rgb: #24b2b2 !important;
  --bs-btn-active-color: #24b2b2 !important;
  --bs-btn-active-border-color: #24b2b2 !important;
  --bs-btn-disabled-color: #24b2b2 !important;
  --bs-btn-disabled-bg: #24b2b2 !important;
  --bs-btn-disabled-border-color: #24b2b2 !important;
  border: none;
}

.female_icon {
  /*background-image: url('../img/png/ic_female.svg');*/
  background-repeat: no-repeat;
  height: 65px;
  width: 65px;
  padding: 30px;
  background-position: center center;
  box-shadow: 0px 10px 20px rgb(29 161 242 / 30%);
  border: 1px solid #f0f0f0;
  border-radius: 20px;
  --bs-btn-bg: #ffffff;
}

.female_icon:hover {
  --bs-btn-hover-bg: #24b2b2 !important;
  --bs-btn-active-bg: #24b2b2 !important;
  --bs-btn-active-shadow: none !important;
  --bs-btn-border-color: #24b2b2 !important;
  --bs-btn-hover-color: #24b2b2 !important;
  --bs-btn-hover-border-color: #24b2b2 !important;
  --bs-btn-focus-shadow-rgb: #24b2b2 !important;
  --bs-btn-active-color: #24b2b2 !important;
  --bs-btn-active-border-color: #24b2b2 !important;
  --bs-btn-disabled-color: #24b2b2 !important;
  --bs-btn-disabled-bg: #24b2b2 !important;
  --bs-btn-disabled-border-color: #24b2b2 !important;
  border: none;
}

.male_title {
  font-family: "Mulish";
  font-size: 15px;
  font-weight: 700;
  text-align: center;
  color: #1e1f20;
  border: 1px solid #f0f0f0;
  border-radius: 20px;
  --bs-btn-bg: #ffffff;
}

.female_icon:hover {
  --bs-btn-hover-bg: #24b2b2 !important;
  --bs-btn-active-bg: #24b2b2 !important;
  --bs-btn-active-shadow: none !important;
  --bs-btn-border-color: #24b2b2 !important;
  --bs-btn-hover-color: #24b2b2 !important;
  --bs-btn-hover-border-color: #24b2b2 !important;
  --bs-btn-focus-shadow-rgb: #24b2b2 !important;
  --bs-btn-active-color: #24b2b2 !important;
  --bs-btn-active-border-color: #24b2b2 !important;
  --bs-btn-disabled-color: #24b2b2 !important;
  --bs-btn-disabled-bg: #24b2b2 !important;
  --bs-btn-disabled-border-color: #24b2b2 !important;
  border: none;
}

.fill_svg {
  fill: #9393aa;
}

.svg_icon {
  margin-top: -23px;
  margin-left: -12px;
}

.svg_icon_1 {
  margin-top: -30px;
  margin-left: -12px;
}

input[type="radio"]:checked {
  background: #000;
  padding: 300px;
}

.radio-container {
  display: flex;
}

.radio_box {
  padding: 10px 0;
  margin-right: 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}

.radio_box label {
  background-repeat: no-repeat;
  height: 65px;
  width: 65px;
  background-position: center center;
  box-shadow: 0px 10px 20px rgb(29 161 242 / 30%);
  border-radius: 20px;
  background: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.gender_title {
  font-family: "Mulish";
  font-size: 15px;
  font-weight: 500;
  text-align: center;
  color: #1e1f20;
  margin-top: 20px;
}

.radio_box.selected label {
  background: #24b2b2 !important;
}

.radio_box.selected .gender_title {
  font-weight: 700;
}

.radio_box label input[type="radio"]:checked {
  --bs-btn-bg: #2ee2ae;
  background: #000;
}

.skip_btn {
  font-family: "Mulish";
  font-weight: 600;
  font-size: 15px;
  background: linear-gradient(45deg, #3093bb 0%, #82dbff 100%);
  border: 1px solid #e0e0e0;
  border-radius: 12px;
  color: #fff;
  padding: 10px 30px;
}

.close_btn_1 {
  font-family: "Mulish";
  font-weight: 600;
  font-size: 15px;
  background: #ffffff;
  border: 1px solid #e0e0e0;
  border-radius: 12px;
  color: #9393aa;
  padding: 10px 25px;
  margin-right: 10px;
}

.skip_registration_title {
  font-family: Mulish;
  font-weight: 600;
  font-size: 18px;
  margin-top: 15px;
  color: #1d1929;
}

.only_verified {
  font-family: "Mulish";
  font-weight: 600;
  font-size: 15px;
  line-height: 24px;
  color: #1d1929;
}

.back_btn {
  font-family: "Mulish";
  font-weight: 600;
  font-size: 15px;
  background: #ffffff;
  border: 1px solid #e0e0e0;
  border-radius: 12px;
  color: #9393aa;
  padding: 10px 40px;
  margin-right: 10px;
}

.continue_btn {
  font-family: Mulish;
  font-weight: 700;
  font-size: 16px;
  background: linear-gradient(45deg, #3093bb 0%, #82dbff 100%);
  width: 100%;
  padding: 15px 20px;
  border: none;
  border-radius: 12px;
  color: #fff;
}

.language_search_icon {
  margin-top: -35px;
  position: absolute;
  right: 15px;
  cursor: pointer;
}

.tag_name_box {
  background: #24b2b2;
  border-radius: 4px;
  padding: 5px 5px 8px 10px;
  color: #fff;
}

.form_bottom {
  margin-bottom: 15px !important;
}

.close_icon {
  padding: 0 5px 0 10px;
  margin-top: -3px;
}

/*---------------------------------------------User Profile Css End---------------------------------------------*/

/*---------------------------------------------Homepage Header Css Start---------------------------------------------*/

.headerNav {
  background: #ffffff;
  box-shadow: 0px 5px 10px rgba(0, 64, 128, 0.04);
  font-family: "Mulish", sans-serif;
}

.nav-link {
  color: #9393aa !important;
  text-decoration: none !important;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
}

.nav-link .active {
  color: #3093bb !important;
  text-decoration: none;
}

.dropdown-menu.show {
  display: block;
  position: absolute;
  top: 50px;
  left: -60px;
  box-shadow: 0px 5px 10px rgba(0, 64, 128, 0.04);
  border-radius: 16px;
  border: none;
}

.switch {
  position: relative;
  display: inline-block;
  width: 46px;
  height: 26px;
  margin-top: -8px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider.round {
  border-radius: 26px;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 4px;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
  height: 28px;
  width: 49px;
}

.slider.round:before {
  border-radius: 50%;
}

.slider:before {
  position: absolute;
  content: "";
  height: 19px;
  width: 19px;
  top: 4px;
  left: 2px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:checked+.slider {
  background-color: #2196f3;
}

input:focus+.slider {
  box-shadow: 0 0 1px #2196f3;
}

input:checked+.slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

.avatar {
  vertical-align: middle;
  width: 40px;
  height: 40px;
  border-radius: 50%;
}

.go_online {
  font-family: "Mulish";
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
  color: #24b2b2;
}

.profile_name {
  font-family: "Mulish";
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  color: #1e1f20;
  margin-right: 4px;
  max-width: 10px !important;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

/*---------------------------------------------Homepage Header Css End---------------------------------------------*/

/*---------------------------------------------Admin Body Css Start---------------------------------------------*/
.texts {
  color: #9393aa;
}

.cards-layout {
  display: flex;
  flex-direction: row;
  gap: 50px;
  border: none;
  font-family: "Mulish", sans-serif;
}

.card-body {
  background: #ffffff;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.05);
  border-radius: 12px;
  text-align: center;
  width: 264px;
}

.card-body .float-right {
  float: right;
}

.card_dynamicnum {
  font-weight: 800;
  font-size: 32px;
  line-height: 40px;
  color: #1e1f20;
}

.admin-bottom-content {
  font-family: "Mulish", sans-serif;
  max-width: 408px;
  margin: auto;
}

.welcome-text {
  font-family: "Mulish", sans-serif;
  font-style: normal;
  font-weight: 800;
  font-size: 24px;
  line-height: 40px;
  color: #1e1f20;
}

.medical-text {
  font-family: "Mulish", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 24px;
  color: #f8446c;
}

.profile-bottom-text {
  font-family: "Mulish", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 24px;
  color: #9393aa;
}

.avatar2 {
  width: 136px;
  height: 136px;
}

.joincommnity {
  font-family: "Mulish", sans-serif;
  font-weight: 700;
  font-size: 20px;
  line-height: 32px;
}

.pleasefill {
  font-family: "Mulish", sans-serif;
  font-weight: 400;
  font-size: 15px;
  line-height: 24px;
}

.profile_btn {
  font-family: "Mulish", sans-serif;
  font-weight: 700;
  font-size: 16px;
  background: linear-gradient(45deg, #3093bb 0%, #82dbff 100%);
  width: 100%;
  padding: 15px 20px;
  border: none;
  border-radius: 12px;
  color: #fff;
  margin-top: 50px;
}

/*---------------------------------------------Admin Body Css End---------------------------------------------*/

.tag_name_box {
  background: #24b2b2;
  border-radius: 4px;
  padding: 5px 5px 8px 10px;
  color: #fff;
}

.form_bottom {
  margin-bottom: 15px !important;
}

.close_icon {
  padding: 0 5px 0 10px;
  margin-top: -3px;
}

.added_qualifications {
  font-family: "Mulish";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 16px;
  color: #9393aa;
}

.qualification_text {
  font-family: "Mulish";
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 24px;
  color: #1e1f20;
}

.remove_title {
  font-family: "Mulish";
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 16px;
  color: #f8446c;
  cursor: pointer;
}

h5.certificate_name {
  font-family: "Mulish";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #1e1f20;
  margin-top: 5px;
  margin-bottom: 0px;
  overflow: hidden;
  text-overflow: ellipsis !important;
  width: 80% !important;
  white-space: nowrap !important;
}

.delete_photo {
  font-family: "Mulish";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 16px;
  text-decoration-line: underline;
  color: #9393aa;
  margin-top: 5px;
  cursor: pointer;
}

.attach_certificate {
  font-family: "Mulish";
  font-style: normal;
  font-weight: 700;
  font-size: 15px;
  line-height: 24px;
  text-align: center;
  color: #ffffff;
  background: #24b2b2;
  border-radius: 12px;
  padding: 15px 30px;
  border: none;
  width: 100%;
}

.attach_icon {
  padding-right: 5px;
}

.bottom_border {
  border: 1px solid #3093bb !important;
}

.id_proof_doc {
  font-family: "Mulish";
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 26px;
  color: #1e1f20;
}

.aadhar_text {
  font-family: "Mulish";
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 24px;
  color: #1e1f20;
  margin-top: 15px;
}

.upload_signature {
  font-family: "Mulish";
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 24px;
  color: #1e1f20;
  margin-top: 15px;
}

.submit_btn {
  font-family: Mulish;
  font-weight: 700;
  font-size: 16px;
  background: linear-gradient(45deg, #3093bb 0%, #82dbff 100%);
  width: 100%;
  padding: 15px 20px;
  border: none;
  border-radius: 12px;
  color: #fff;
}

.submit_profile {
  font-family: Mulish;
  font-weight: 600;
  font-size: 18px;
  margin-top: 15px;
  color: #1d1929;
}

.modal_header {
  border-bottom: none !important;
}

.preferred_schedule {
  font-family: "Mulish";
  font-weight: 600;
  font-size: 15px;
  line-height: 24px;
  color: #f8446c;
}

.set_up_btn {
  font-family: "Mulish";
  font-weight: 600;
  font-size: 15px;
  background: linear-gradient(45deg, #3093bb 0%, #82dbff 100%);
  border: 1px solid #e0e0e0;
  border-radius: 12px;
  color: #fff;
  padding: 10px 30px;
}

.upload_avatar_img {
  width: 75px;
  max-width: 50px;
  max-height: 50px;
  border-radius: 12px;
  object-fit: cover;
}

.personal_profile_card_body .upload_avatar_img {
  width: 112px;
  height: 112px;
  max-width: 90px;
  max-height: 90px;
}

button.personal_profile_btn.float_right.btn.btn-primary img {
  margin: 0 15px 0 0;
  height: 20px;
  width: 20px;
}

/*---------------------------------------------User Profile Css End---------------------------------------------*/
/*---------------------------------------------Doctor Appointment Css Start---------------------------------------------*/
.doc_appointment_head {
  font-family: Mulish;
  font-size: 24px;
  font-weight: 800;
  color: #1e1f20;
  margin-top: 15px;
  margin-bottom: 20px;
}

.doc_appointment_title {
  font-family: "Mulish";
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 26px;
  color: #1e1f20;
}

.doc_appointment_form_title {
  font-family: Mulish;
  font-size: 16px;
  color: #000;
  line-height: 20px;
  font-weight: 600;
}

.fee_card {
  font-family: "Mulish";
  font-style: normal;
  font-weight: 700;
  font-size: 15px;
  line-height: 24px;
  text-align: center;
  color: #3093bb;
  background: #ffffff;
  border: 1px solid #3093bb;
  border-radius: 8px;
  padding: 8px 34px;
  height: 48px;
  margin-top: 25px;
}

.consult_fee_subtext {
  font-family: "Mulish";
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  line-height: 16px;
  color: #f8446c;
}

.time_slot {
  font-family: "Mulish";
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 26px;
  color: #1e1f20;
}

/*---------------------------Tabs Section-------------------------*/

.nav-tabs .nav-link {
  font-family: "Mulish";
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 32px;
  color: #9393aa;
  border: none;
}

.nav-tabs {
  border: none;
}

.nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link.active {
  font-family: "Mulish";
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 32px;
  color: #24b2b2;
}

.nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link.active:after {
  content: "";
  border-bottom: 3px solid #24b2b2;
  width: 30px;
  transform: rotate(180deg);
  position: absolute;
  margin-top: 35px;
  margin-left: -40px;
}

.tab_inner_box {
  padding: 20px 0px;
}

/*---------------------------Tabs Section-------------------------*/
.day_box {
  display: inline-flex;
  column-gap: 15px;
}

.days_bg {
  background: #ffffff;
  border: 1px solid #e0e0e0;
  padding: 8px;
  border-radius: 50px;
  height: 45px;
  width: 45px;
}

.days_bg_2 {
  background: linear-gradient(45deg, #3093bb 0%, #82dbff 100%);
  border: 1px solid #e0e0e0;
  padding: 8px;
  border-radius: 50px;
  height: 45px;
  width: 45px;
}

.days_bg_3 {
  background: #24b2b2;
  border: 1px solid #e0e0e0;
  padding: 8px;
  border-radius: 50px;
  height: 45px;
  width: 45px;
}

.day_label {
  width: 50px;
}

.hide_radio_btn {
  margin-top: -65px;
  position: relative;
  width: 44px;
  height: 33px;
  right: 0;
  margin-left: 0px;
  border-radius: 45px;
}

.accordion-button::after {
  flex-shrink: 0;
  width: var(--bs-accordion-btn-icon-width);
  height: var(--bs-accordion-btn-icon-width);
  margin-left: auto;
  content: "";
  background-image: url("../img/svg/accordien_plus.svg");
  background-repeat: no-repeat;
  background-size: var(--bs-accordion-btn-icon-width);
}

.accordion-button:not(.collapsed)::after {
  background-image: url("../img/svg/accordien_minus.svg");
  transform: none;
  margin-top: 2px;
  margin-bottom: -15px;
}

.accordion {
  --bs-accordion-border-color: none !important;
  --bs-accordion-bg: transparent;
}

.accordion-button:not(.collapsed) {
  background: transparent !important;
  font-family: "Mulish";
  font-style: normal;
  font-weight: 700;
  font-size: 17px;
  line-height: 24px;
  color: #1e1f20;
}

.accordion-button {
  font-family: "Mulish";
  font-style: normal;
  font-weight: 700;
  font-size: 17px;
  line-height: 24px;
  color: #1e1f20;
  text-transform: capitalize;
}

.start_at {
  font-family: "Mulish";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 16px;
  color: #1e1f20;
}

.end_at {
  font-family: "Mulish";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 16px;
  color: #1e1f20;
}

.watch_icon {
  position: absolute;
  margin-left: 140px;
  margin-top: -50px;
  background: #fff;
}

.watch_icon_2 {
  position: absolute;
  margin-left: 140px;
  margin-top: -50px;
  background: #fff;
}

.emergency_call_text {
  font-family: "Mulish";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  color: #1e1f20;
  margin-left: 15px;
  margin-top: -4px;
  position: absolute;
}

.checkbox_icon {
  width: 18px;
  height: 18px;
}

.weekdays_box {
  padding: 10px 10px 10px 10px;
}

.weekends_box {
  padding: 10px 10px 10px 10px;
}

.fee_card_modal {
  width: 700px;
}

.fee_card_title {
  font-family: "Mulish";
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 32px;
  color: #1e1f20;
}

.fee_card_table_head {
  font-family: "Mulish";
  font-style: normal;
  font-weight: 700;
  font-size: 15px;
  line-height: 24px;
  color: #24b2b2;
}

.modal-header .btn-close {
  padding: calc(var(--bs-modal-header-padding-y) * 0.5) calc(var(--bs-modal-header-padding-x) * 0.5);
  margin: calc(var(--bs-modal-header-padding-y) * -0.5) calc(var(--bs-modal-header-padding-x) * -0.5) calc(var(--bs-modal-header-padding-y) * -0.5) auto;
  border: 1px solid #e0e0e0;
}

.table_text {
  font-family: "Mulish";
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 24px;
  color: #1e1f20;
  padding: 10px 0;
}

.modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  color: var(--bs-modal-color);
  pointer-events: auto;
  background-color: var(--bs-modal-bg);
  background-clip: padding-box;
  border: var(--bs-modal-border-width) solid var(--bs-modal-border-color);
  border-radius: var(--bs-modal-border-radius);
  outline: 0;
  border-radius: 16px;
}

.upi_title {
  font-family: "Mulish";
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 26px;
  color: #1e1f20;
}

.details_submitted {
  font-family: Mulish;
  font-weight: 600;
  font-size: 18px;
  margin-top: 15px;
  color: #1d1929;
}

.go_to_home {
  font-family: "Mulish";
  font-weight: 600;
  font-size: 15px;
  background: linear-gradient(45deg, #3093bb 0%, #82dbff 100%);
  border: 1px solid #e0e0e0;
  border-radius: 12px;
  color: #fff;
  padding: 10px 30px;
  width: -webkit-fill-available;
}

.submit_successfully_text {
  font-family: "Mulish";
  font-weight: 600;
  font-size: 15px;
  line-height: 24px;
  color: #f8446c;
}

/*----------------------------------------------Doctor Appointment Css End----------------------------------------------*/
/*----------------------------------------------Navbar Css Start----------------------------------------------*/
.tablehead {
  font-family: "Mulish";
  font-style: normal;
  font-weight: 700;
  font-size: 15px;
  line-height: 40px;
  color: #9393aa;
  padding-left: 20px !important;
}

.nav_links {
  font-family: "Mulish";
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 24px;
  color: #9393aa;
  margin-left: 6px;
}

.nav_links_icon {
  margin-top: -4px;
}

.nav_links_active {
  font-family: "Mulish";
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 24px;
  color: #3093bb;
  margin-left: 6px;
}

.dropdown-toggle::after {
  border: none !important;
  background-image: url("../img/png/dropdown_arrow.png");
  width: 20px;
  height: 9px;
  background-repeat: no-repeat;
  background-position: center center;
  margin-top: 12px;
}

.online_toggle_text {
  font-family: "Mulish";
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #24b2b2;
  margin-left: 12px;
}

.userprofile_name {
  font-family: "Mulish";
  font-weight: 600;
  font-size: 15px;
  line-height: 24px;
  color: #1e1f20;
  margin-right: 4px;
}

.userprofile_subtext {
  font-family: "Mulish";
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  line-height: 16px;
  color: #9393aa;
}

.dropdown_icon {
  width: auto;
}

/*-----------------------------------------------Navbar Css End-----------------------------------------------*/
/*----------------------------------------------Payout Css Start----------------------------------------------*/
.payout_container {
  background: #f8fbff;
  padding: 30px 100px;
}

.payout_card_box {
  background: #ffffff;
  box-shadow: 0px 5px 10px rgb(0 64 128 / 4%);
  border-radius: 12px 12px 0px 0px;
  padding: 24px 20px;
  height: 650px;
}

.payout_table {
  border: none !important;
  border-bottom-width: 0px !important;
}

.payout_table_head {
  background: #f8fbff;
  border-radius: 12px;
  padding: 16px;
  height: 56px;
}

.payout_table_body_row {
  height: 56px;
  border-bottom: 1px solid #eceff4;
}

.payout_table_head_text {
  font-family: "Mulish";
  font-style: normal;
  font-weight: 700;
  font-size: 15px;
  line-height: 40px;
  color: #9393aa;
  padding-left: 20px !important;
}

.payout_table_body_text {
  font-family: "Mulish";
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 40px;
  color: #1e1f20;
  padding-left: 20px !important;
}

.table> :not(caption)>*>* {
  padding: 0.5rem 0.5rem;
  background-color: var(--bs-table-bg);
  border-bottom-width: 0px !important;
  box-shadow: inset 0 0 0 9999px var(--bs-table-accent-bg);
}

.paid_tag {
  background: rgba(47, 192, 139, 0.15);
  border-radius: 6px;
  padding: 6px 10px;
  font-family: "Mulish";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
  color: #2fc08b;
}

.failed_tag {
  background: rgba(228, 75, 65, 0.15);
  border-radius: 6px;
  padding: 6px 10px;
  font-family: "Mulish";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
  color: #e44b41;
}

.in_prcoess {
  background: rgba(83, 121, 219, 0.15);
  border-radius: 6px;
  padding: 6px 10px;
  font-family: "Mulish";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
  color: #5379db;
}

.completed {
  background: rgba(47, 192, 139, 0.15);
  border-radius: 6px;
  padding: 6px 10px;
  font-family: "Mulish";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
  color: #2fc08b;
}

.withdraw_btn {
  background: linear-gradient(45deg, #3093bb 0%, #82dbff 100%);
  color: #ffffff;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.05);
  border-radius: 8px;
  border: none;
  font-family: "Mulish";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
  padding: 10px 30px;
}

.withdraw_balance_card {
  background: #ffffff;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.05);
  border-radius: 8px;
  padding: 10px 16px;
}

.withdraw_balance_text {
  font-family: "Mulish";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
  color: #1e1f20;
  margin-bottom: 0;
}

.withdraw_balance_amount {
  color: #f6587b;
}

.payout_search_box {
  background: #ffffff;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.05);
  border-radius: 8px;
  padding: 3px;
  width: 300px;
}

.payout_search {
  width: 15px;
  margin-left: 15px;
  margin-top: 1px;
  position: absolute;
}

.payout_title {
  font-family: "Mulish";
  font-style: normal;
  font-weight: 800;
  font-size: 24px;
  line-height: 40px;
  color: #1e1f20;
}

.pagination_result_text {
  font-family: "Mulish";
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  line-height: 16px;
  display: flex;
  align-items: center;
  color: #9393aa;
  margin-bottom: 0px;
  margin-top: 10px;
}

.pagination_card {
  background: #ffffff;
  box-shadow: 0px 5px 10px rgb(0 64 128 / 4%);
  border-radius: 0px 0px 12px 12px;
  padding: 15px 20px;
  border-top: 1px solid #eceff4;
}

.pagination_card .form-select {
  background: none;
  padding: 0px 20px !important;
  border-width: 0;
  width: 100%;
  height: 40px !important;
  color: transparent;
  transition: all 0.28s ease;
  box-shadow: none;
  font-family: Mulish;
  font-weight: 600;
  font-size: 16px;
  color: #1d1929;
  border: 1px solid #ede8e9;
  border-radius: 10px;
  background-color: #fff;
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAoAAAAGCAYAAAD68A/GAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAACNSURBVHgBbY3RCYNADIZzbSl9qb3HQmn4u4GPfe0k7SgdwQ3UDdzAEcQNDl3gdIEzEQ9E/CHhT/L9hEhkAfsAUtroDiD6g0JJoPootYbVnwM1T0au88k756+M1hhKF/ijB/X6TMpQbCpJFgJ/xfplZUOgsu/cbw5GcBx8ldzsS+C3jJc1tCtmZAz8t/sJ0TAl3Z9eur4AAAAASUVORK5CYII=);
  background-repeat: no-repeat;
  background-position: right 0.75rem center;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  appearance: none;
  display: block;
}

.page_item_list {
  font-family: "Mulish";
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 22px;
  display: flex;
  align-items: center;
  color: #9393aa;
  margin-right: 15px;
  margin-bottom: 0px;
  margin-top: 10px;
}

.item_list_box {
  height: 40px;
  margin-top: -5px;
}

.item_drop_box {
  height: 40px !important;
}

.pagination_content {
  justify-content: center;
  --bs-pagination-border-width: 0;
  column-gap: 10px;
  margin-bottom: 3px;
}

.page-item:first-child .page-link {
  background-image: url("../img/png/left_pagination.png");
  background-repeat: no-repeat;
  background-position: center center;
}

.page-item:last-child .page-link {
  background-image: url("../img/png/right_pagination.png");
  background-repeat: no-repeat;
  background-position: center center;
}

.page-link {
  font-family: "Mulish";
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 24px;
  display: flex;
  align-items: center;
  color: #1e1f20 !important;
}

.active>.page-link,
.page-link.active {
  color: #3093bb !important;
  background: #fff;
}

.requiest_btn {
  background: #f0f0f0 !important;
  color: #9393aa !important;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.05);
  border-radius: 8px;
  border: none;
  font-family: "Mulish";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
  padding: 10px 30px;
}

.payout_date {
  font-family: "Mulish";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: #9393aa;
}

.payout_back_btn {
  background: #ffffff;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.05);
  border-radius: 8px;
  font-family: "Mulish";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
  color: #1e1f20;
  padding: 10px 25px;
  border: none;
}

.back_btn_icon {
  width: 22px;
  height: 22px;
  margin-left: -5px;
  margin-top: -5px;
  margin-right: 8px;
}

/*-----------------------------------------------Payout Css End-----------------------------------------------*/
/*-----------------------------------------------Profile Css Start-----------------------------------------------*/
.profile_container {
  background: #f8fbff;
  padding: 30px 100px;
  min-height: 100vh;
}

.profile_title {
  font-family: "Mulish";
  font-style: normal;
  font-weight: 800;
  font-size: 24px;
  line-height: 40px;
  color: #1e1f20;
}

.profile_tab_card {
  background: #ffffff;
  box-shadow: 0px 5px 10px rgb(0 64 128 / 4%);
  padding: 20px 16px 20px 16px;
  border-radius: 12px;
  height: 100%;
}

.profile_tab_option_active_bg {
  background: #f8fbff;
  border-radius: 12px;
  padding: 13px 15px;
}

.profile_tab_option_bg {
  background: #ffffff;
  border-radius: 12px;
  padding: 13px 15px;
}

.profile_option_icon {
  margin-top: -7px;
}

.profile_option_icon_active {
  margin-top: -7px;
}

.profile_ul {
  padding: 0px;
}

.profile_option_text_active {
  font-family: "Mulish";
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 24px;
  color: #3093bb;
  margin-left: 20px;
}

.profile_arrow_active {
  margin-top: 5px;
  float: right;
}

.profile_option_text {
  font-family: "Mulish";
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 24px;
  color: #9393aa;
  margin-left: 20px;
}

.personal_profile_card_head {
  background: #ffffff;
  box-shadow: 0px 5px 10px rgba(0, 64, 128, 0.04);
  border-radius: 12px 12px 0 0;
  padding: 15px 20px;
  border-bottom: 1px solid #eceff4;
}

.personal_profile_card_body {
  background: #ffffff;
  box-shadow: 0px 5px 10px rgba(0, 64, 128, 0.04);
  border-radius: 0 0 12px 12px;
  padding: 25px;
}

.personal_profile_title {
  font-family: "Mulish";
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 32px;
  color: #1e1f20;
  margin-top: 5px;
  margin-bottom: 0;
}

.personal_profile_btn {
  background: #ffffff;
  border: 1px solid #eceff4;
  border-radius: 8px;
  font-family: "Mulish";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #1e1f20;
  padding: 8px 15px;
}

.personal_profile_btn_icon {
  margin-right: 15px;
  width: 18px;
  margin-top: -5px;
}

.personal_profile_title::before {
  content: "";
  background: #fc9875;
  border: 1px solid #fc9875;
  padding: 3px;
  border-radius: 10px;
  margin-right: 10px;
}

.profile_gender_btn_text {
  font-family: "Mulish";
  font-size: 15px;
  font-weight: 700;
  text-align: center;
  color: #1e1f20;
  margin-top: 15px;
  position: absolute;
  margin-left: -18px;
}

.edit_profile_save_btn {
  font-family: "Mulish";
  font-weight: 700;
  font-size: 16px;
  background: linear-gradient(45deg, #3093bb 0%, #82dbff 100%);
  width: 22%;
  min-width: 130px;
  padding: 15px 20px;
  border: none;
  border-radius: 12px;
  color: #fff;
  margin-top: 0px;
}

.work_profile_title {
  font-family: "Mulish";
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 32px;
  color: #1e1f20;
  margin-top: 5px;
  margin-bottom: 0;
}

.work_profile_title::before {
  content: "";
  background: #f8446c;
  border: 1px solid #f8446c;
  padding: 3px;
  border-radius: 10px;
  margin-right: 10px;
}

.work_profile_tag_box {
  background: #24b2b2;
  border-radius: 4px;
  padding: 5px 10px 8px 10px;
  color: #fff;
}

.work_profile_card_head {
  background: #fff;
  box-shadow: 0px 5px 10px rgba(0, 64, 128, 0.04);
  border-radius: 12px 12px 0 0;
  padding: 15px 20px;
  border-bottom: 1px solid #eceff4;
}

.work_profile_card_body {
  background: #fff;
  box-shadow: 0px 5px 10px rgba(0, 64, 128, 0.04);
  border-radius: 0 0 0 0px;
  padding: 25px;
}

.work_profile_bottom_border {
  border-bottom: 1px solid #eceff4;
}

.work_profile_card_body_1 {
  background: #fff;
  box-shadow: 0px 5px 10px rgba(0, 64, 128, 0.04);
  border-radius: 0 0 0px 0px;
  padding: 25px 25px 25px 25px;
  border-top: 1px solid #eceff4;
}

.work_profile_title_qualification {
  font-family: "Mulish";
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 32px;
  color: #1e1f20;
  margin-top: 5px;
  margin-bottom: 0;
}

.work_profile_title_qualification::before {
  content: "";
  background: #fc9875;
  border: 1px solid #fc9875;
  padding: 3px;
  border-radius: 10px;
  margin-right: 10px;
}

.qualification_title {
  font-family: "Mulish";
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 24px;
  color: #1e1f20;
}

.work_profile_title_upload_id {
  font-family: "Mulish";
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 32px;
  color: #1e1f20;
  margin-top: 5px;
  margin-bottom: 0;
}

.work_profile_title_upload_id::before {
  content: "";
  background: #24b2b2;
  border: 1px solid #24b2b2;
  padding: 3px;
  border-radius: 10px;
  margin-right: 10px;
}

/*------------------------------------------------Profile Css End------------------------------------------------*/
/*---------------------------------------------Datatable one two modal Css Start---------------------------------------------*/
.button-spaces {
  gap: 20px;
}

.cancel-button,
.datepicker,
.search {
  background: #ffffff;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.05);
  border-radius: 8px;
}

.cancel-button .btn {
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
  color: #f8446c;
}

.search {
  width: 300px;
}

.custom-select {
  background: #ffffff;
  /* box-shadow: 0px 2px 4px rgb(0 0 0 / 5%); */
  border-radius: 8px;
  border: 1px solid #eceff4;
  width: 110px;
  height: 48px;
}

.consultation-modal-body .modal-content {
  margin: auto;
  max-width: 350px;
  width: 100%;
}

.consultation-modal-text {
  font-family: "Mulish";
  font-size: 18px;
  line-height: 32px;
  font-weight: 700;
}

.consultation-modal-header {
  border: none;
  padding-bottom: 0;
}

.checkbox_input span {
  font-family: "Mulish";
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 24px;
  color: #1e1f20;
}

.checkbox_input {
  display: flex;
  justify-content: flex-start;
  gap: 20px;
  padding: 10px 15px;
}

.optional-note {
  font-family: "Mulish";
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  line-height: 16px;
  color: #1e1f20;
}

.optional-note p {
  margin-bottom: 8px;
  margin-left: 10px;
}

.optional-note-text {
  background: #ffffff;
  border: 1px solid #f0f0f0;
  border-radius: 8px;
  width: 95%;
  margin-left: 10px;
  margin-right: 10px;
  padding: 10px;
}

.consultation-modal-footer {
  justify-content: space-around;
  border: none;
  width: 100%;
}

.appoinment_input {
  display: flex;
  justify-content: space-between;
  padding: 15px 10px;
  border-bottom: 1px solid #eceff4;
}

.appoinment_input .left_text {
  font-family: "Mulish";
  font-style: normal;
  font-weight: 700;
  font-size: 15px;
  line-height: 24px;
  color: #9393aa;
}

.appoinment_input .right-text {
  font-family: "Mulish";
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 24px;
  margin-bottom: 0;
}

.appoinment_input span {
  text-align: right;
  color: #9393aa;
}

.consultation-modal-body-two .modal-content {
  margin: auto;
  max-width: 450px;
  width: 100%;
}

.Tabs .nav {
  font-weight: 600;
  font-size: 20px;
  line-height: 32px;
  color: #9393aa;
}

.Tabs .nav {
  display: flex;
  gap: 20px;
}

.Tabs .nav-item {
  cursor: pointer;
}

.Tabs .nav-item .nav-link {
  font-size: 18px;
  line-height: 18px;
  text-decoration: none;
}

.Tabs .nav-item .nav-link.active {
  color: #24b2b2 !important;
  border-bottom: 2px solid #24b2b2;
  font-size: 18px;
  line-height: 18px;
  text-decoration: none;
}

.cancel-button .active {
  display: none;
}

.datepicker.active {
  display: none;
}

.consultation-heading {
  font-family: "Mulish";
  font-style: normal;
  font-weight: 800;
  font-size: 24px;
  line-height: 40px;
  color: #1e1f20;
}

.consultation_card_box {
  background: #ffffff;
  box-shadow: 0px 5px 10px rgb(0 64 128 / 4%);
  border-radius: 12px 12px 0px 0px;
  padding: 24px 20px;
  min-height: 650px;
}

.consultation_table {
  border: none !important;
  border-bottom-width: 0px !important;
}

.consultation_table_head {
  background: #f8fbff;
  border-radius: 12px;
  padding: 16px;
  height: 56px;
}

th:first-child {
  border-top-left-radius: 12px;
  border-bottom-left-radius: 12px;
}

th:last-child {
  border-top-right-radius: 12px;
  border-bottom-right-radius: 12px;
}

.consultation_table_head_text {
  font-family: "Mulish";
  font-style: normal;
  font-weight: 700;
  font-size: 15px;
  line-height: 40px;
  color: #9393aa;
  padding-left: 20px !important;
}

.consultation_table_body_row {
  height: 56px;
  border-bottom: 1px solid #eceff4;
}

.consultation_table_body_text {
  font-family: "Mulish";
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 40px;
  color: #1e1f20;
  padding-left: 20px !important;
}

/*------------------------------------------------Datatable one two modal css end -----------------------------------------------*/
/*------------------------------------------------Prescription one css Start -----------------------------------------------*/
/* SideNAv */

.nav_container {
  display: flex;
  gap: 10px;
}

.pro-sidebar {
  background: #ffffff;
  box-shadow: 0px 5px 10px rgba(0, 64, 128, 0.04);
  border-radius: 12px;
  width: 245px;
  height: 734px;
}

.sidebar_nav {
  display: flex;
  gap: 20px;
  font-weight: 600;
  font-size: 15px;
  line-height: 24px;
  padding: 15px;
}

.pro-menu ul a {
  text-decoration: none;
}

.nav_text.active {}

/* medicine css */

.medicine_card_box {
  background: #ffffff;
  box-shadow: 0px 5px 10px rgb(0 64 128 / 4%);
  border-radius: 12px 12px 0px 0px;
  padding: 24px 20px;
  min-height: 650px;
}

/*------------------------------------------------Prescription one css end -----------------------------------------------*/

/*-------------------------------------------------Setting Css start-------------------------------------------------*/
.setting_profile_card_head .setting_bank_title {
  font-family: "Mulish";
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 32px;
  color: #1e1f20;
  margin-top: 5px;
  margin-bottom: 0;
}

.setting_profile_card_head .setting_bank_title::before {
  content: "";
  background: #fc9875;
  border: 1px solid #fc9875;
  padding: 3px;
  border-radius: 10px;
  margin-right: 10px;
}

.setting_profile_card_head .setting_change_password {
  font-family: "Mulish";
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 32px;
  color: #1e1f20;
  margin-top: 5px;
  margin-bottom: 0;
}

.setting_profile_card_head .setting_change_password::before {
  content: "";
  background: #3093bb;
  border: 1px solid #3093bb;
  padding: 3px;
  border-radius: 10px;
  margin-right: 10px;
}

.setting_profile_card_head .setting_change_mobile {
  font-family: "Mulish";
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 32px;
  color: #1e1f20;
  margin-top: 5px;
  margin-bottom: 0;
}

.setting_profile_card_head .setting_change_mobile::before {
  content: "";
  background: #f8446c;
  border: 1px solid #f8446c;
  padding: 3px;
  border-radius: 10px;
  margin-right: 10px;
}

.setting_profile_card_head .setting_profile_title {
  font-family: "Mulish";
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 32px;
  color: #1e1f20;
  margin-top: 5px;
  margin-bottom: 0;
}

.setting_profile_card_head .setting_profile_title::before {
  content: "";
  background: #f8446c;
  border: 1px solid #f8446c;
  padding: 3px;
  border-radius: 10px;
  margin-right: 10px;
}

.send_otp {
  font-family: "Mulish";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 16px;
  color: #f8446c;
  position: absolute;
  float: right;
  right: 20px;
  margin-top: -42px;
}

.update_number {
  font-family: "Mulish";
  font-weight: 600;
  font-size: 15px;
  line-height: 24px;
  color: #f8446c;
}

.ok_btn {
  font-family: "Mulish";
  font-weight: 600;
  font-size: 15px;
  background: linear-gradient(45deg, #3093bb 0%, #82dbff 100%);
  border: 1px solid #e0e0e0;
  border-radius: 12px;
  color: #fff;
  width: 100%;
  padding: 10px 30px;
}

.setting_profile_btn {
  background: #ffffff;
  border: 1px solid #eceff4;
  border-radius: 8px;
  font-family: "Mulish";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #1e1f20;
  padding: 8px 15px;
}

.setting_profile_btn_icon {
  margin-right: 15px;
  width: 18px;
  margin-top: -5px;
}

.setting_form_title {
  font-family: Mulish;
  font-size: 16px;
  color: #000;
  line-height: 20px;
  font-weight: 600;
}

.setting_consult_fee_subtext {
  font-family: "Mulish";
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  line-height: 16px;
  color: #f8446c;
}

.setting_time_slot_title {
  font-family: "Mulish";
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 26px;
  color: #1e1f20;
}

.setting_time_slot_title::before {
  content: "";
  background: #24b2b2;
  border: 1px solid #24b2b2;
  padding: 3px;
  border-radius: 10px;
  margin-right: 6px;
}

.setting_profile_card_head {
  background: #ffffff;
  box-shadow: 0px 5px 10px rgb(0 64 128 / 4%);
  border-radius: 12px 12px 0 0;
  padding: 10px 20px;
  border-bottom: 1px solid #eceff4;
}

.setting_profile_card_body {
  background: #ffffff;
  box-shadow: 0px 5px 10px rgba(0, 64, 128, 0.04);
  border-radius: 0 0 12px 12px;
  padding: 25px;
}

.setting_time_slot_day {
  font-family: "Mulish";
  font-style: normal;
  font-weight: 700;
  font-size: 17px;
  line-height: 24px;
  color: #1e1f20;
}

/*-------------------------------------------------Setting Css End-------------------------------------------------*/

/* .......Help & Supports Start */

support-paragraph::-webkit-scrollbar {
  width: 6px;
}

/* Track */
.support-paragraph::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
.support-paragraph::-webkit-scrollbar-thumb {
  background: rgb(206, 202, 202);
}

/* Handle on hover */
.support-paragraph::-webkit-scrollbar-thumb:hover {
  background: rgb(185, 183, 183);
}

.support_container {
  background: #f8fbff;
  padding: 30px 100px;
}

.support_card_box {
  background: #ffffff;
  box-shadow: 0px 5px 10px rgb(0 64 128 / 4%);
  border-radius: 12px 12px 0px 0px;
  padding: 24px 20px;
  padding-bottom: 0;
  padding-left: 0;
  padding-right: 0;
  height: auto;
}

.support-paragraph {
  padding-right: 20px;
  height: 500px;
  overflow-y: scroll;
}

.support_top_heading_card {
  padding-left: 20px;
  padding-right: 20px;
}

.support_bootom_card {
  background: #ffffff;
  box-shadow: 0px -5px 10px rgba(0, 64, 128, 0.04);
  border-radius: 0px 0px 12px 12px;
  padding: 10px 20px;
}

.support_content .date {
  font-family: "Mulish";
  font-style: normal;
  font-weight: 700;
  font-size: 15px;
  line-height: 24px;
  text-align: right;
  color: #1e1f20;
}

.support_bootom_card h5 {
  font-family: "Mulish";
  font-style: normal;
  font-weight: 700;
  font-size: 15px;
  line-height: 24px;
  color: #1e1f20;
  text-align: left;
  margin-bottom: 0;
  padding-top: 5px;
}

.support_bootom_card h6 {
  font-family: "Mulish";
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  text-transform: uppercase;
  color: #9393aa;
  text-align: left;
  margin-bottom: 0;
  padding-top: 5px;
}

.support_content .faq-text {
  font-family: "Mulish";
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  text-transform: uppercase;
  color: #9393aa;
  padding: 0px 20px;
}

.support-accordion-header button {
  font-family: "Mulish";
  font-style: normal;
  font-weight: 500 !important;
  font-size: 24px !important;
  line-height: 40px !important;
  color: #1e1f20 !important;
  margin-left: 10px;
  margin-right: 10px;
}

.support-accordion-header .bullets {
  background: #24b2b2;
  width: 9px;
  height: 9px;
  border-radius: 50%;
}

.accordion-collapse .support-accordion-body-text {
  font-family: "Mulish";
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 26px;
  margin: 5px 20px;
  color: #1e1f20;
}

/* .......Help & Supports Start */

/* ...Privacy & Polisy Start.......... */

privacypolicy-paragraph::-webkit-scrollbar {
  width: 6px;
}

/* Track */
.privacypolicy-paragraph::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
.privacypolicy-paragraph::-webkit-scrollbar-thumb {
  background: rgb(206, 202, 202);
}

/* Handle on hover */
.privacypolicy-paragraph::-webkit-scrollbar-thumb:hover {
  background: rgb(185, 183, 183);
}

.privacypolicy_container {
  background: #f8fbff;
  padding: 30px 100px;
}

.privacypolicy_card_box {
  background: #ffffff;
  box-shadow: 0px 5px 10px rgb(0 64 128 / 4%);
  border-radius: 12px 12px 0px 0px;
  padding: 24px 20px;
  height: 650px;
}

.privacypolicy-paragraph {
  padding-right: 20px;
  height: 500px;
  overflow-y: auto;
}

.privacy_policy_title {
  font-family: "Mulish";
  font-style: normal;
  font-weight: 800;
  font-size: 24px;
  line-height: 40px;
  color: #1e1f20;
}

.privacy_policy_content h5 {
  font-family: "Mulish";
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 32px;
  color: #1e1f20;
}

.privacy_policy_content h6 {
  font-family: "Mulish";
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  line-height: 24px;
  text-align: right;
  text-transform: uppercase;
  color: #9393aa;
}

.privacy_policy_content .date {
  font-family: "Mulish";
  font-style: normal;
  font-weight: 700;
  font-size: 15px;
  line-height: 24px;
  text-align: right;
  color: #1e1f20;
}

.privacy-policy-text {
  font-family: "Mulish";
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 24px;
}

/* ...Privacy & Polisy End...... */

/* ......Terms & Conditions Start */

.termscondition-paragraph::-webkit-scrollbar {
  width: 6px;
}

/* Track */
.termscondition-paragraph::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
.termscondition-paragraph::-webkit-scrollbar-thumb {
  background: rgb(206, 202, 202);
}

/* Handle on hover */
.termscondition-paragraph::-webkit-scrollbar-thumb:hover {
  background: rgb(185, 183, 183);
}

.termscondition_container {
  background: #f8fbff;
  padding: 30px 100px;
}

.termscondition_card_box {
  background: #ffffff;
  box-shadow: 0px 5px 10px rgb(0 64 128 / 4%);
  border-radius: 12px 12px 0px 0px;
  padding: 24px 20px;
  height: 650px;
}

.termscondition-paragraph {
  padding-right: 20px;
  height: 500px;
  overflow-y: auto;
}

.terms_condition_title {
  font-family: "Mulish";
  font-style: normal;
  font-weight: 800;
  font-size: 24px;
  line-height: 40px;
  color: #1e1f20;
}

.terms_condition_content h5 {
  font-family: "Mulish";
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 32px;
  color: #1e1f20;
}

.terms_condition_content h6 {
  font-family: "Mulish";
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  line-height: 24px;
  text-align: right;
  text-transform: uppercase;
  color: #9393aa;
}

.terms-condition-heading {
  font-family: "Mulish";
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 24px;
  margin-bottom: 0px;
}

.terms_condition_content .date {
  font-family: "Mulish";
  font-style: normal;
  font-weight: 700;
  font-size: 15px;
  line-height: 24px;
  text-align: right;
  color: #1e1f20;
}

.terms-condition-text {
  font-family: "Mulish";
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 24px;
}

/* ....Trms & Condition End */

/*------------------------------------------------Datatable one two modal css end -----------------------------------------------*/
/*------------------------------------------------Prescription one css Start -----------------------------------------------*/
/* SideNAv */

.nav_container {
  display: flex;
  gap: 10px;
  width: 100%;
  overflow: hidden;
  background: #f8fbff;
  padding: 30px 100px;
}

.prescription_heading {
  padding: 20px 100px;
  background: #f8fbff;
}

.prescription-search form input {
  background: #f8fbff;
  border-radius: 8px;
  width: 390px;
  padding-top: 13px;
  padding-bottom: 13px;
}

.pro-sidebar-content {
  padding-top: 10px;
  padding-bottom: 10px;
}

.pro-sidebar-content a {
  text-decoration: none;
}

.pro-sidebar-content li {
  list-style: none;
  margin: 5px 10px;
  padding: 12px 10px;
  font-family: "Mulish";
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 24px;
  color: #9393aa;
}

.prescriptionTabactive li {
  background: #f8fbff;
  border-radius: 8px;
  color: #3093bb;
}

.pro-sidebar {
  background: #ffffff;
  box-shadow: 0px 5px 10px rgba(0, 64, 128, 0.04);
  border-radius: 12px;
  /* width: 100%;
  max-width: 265px; */
}

.sidebar_nav {
  display: flex;
  gap: 20px;
  font-weight: 600;
  font-size: 15px;
  line-height: 24px;
  padding: 15px;
}

.capsule_icon {
  height: 43px;
  width: 43px;
}

.medicine_text {
  font-family: "Mulish";
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 24px;
  color: #1e1f20;
}

.pro-menu ul a {
  text-decoration: none;
}

.medicine_header {
  font-family: "Mulish";
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 32px;
  color: #1e1f20;
}

.medicine_search_box {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 20px;
}

.medicine_card_box {
  background: #ffffff;
  box-shadow: 0px 5px 10px rgb(0 64 128 / 4%);
  border-radius: 12px 12px 12px 12px;
  padding: 24px 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.medicine_card_box label {
  font-family: "Mulish";
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  line-height: 16px;
  color: #1e1f20;
}

.Textarea {
  width: 391px;
  height: 119px;
  background: #ffffff;
  border: 1px solid #eceff4;
  border-radius: 8px;
  font-family: "Mulish";
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 24px;
  color: #1e1f20;
}

.follow_up {
  background: #ffffff;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.05);
  border-radius: 8px;
  border: none;
  width: 135px;
  height: 40px;
  padding: 10px;
}

.prescription_table {
  border: none !important;
  border-top: 1px solid #eceff4 !important;
  margin-top: 20px;
}

.prescription_table_head {
  background: #f8fbff;
  border-radius: 12px;
  padding: 16px;
  height: 56px;
}

.prescription_table_head_text {
  font-family: "Mulish";
  font-style: normal;
  font-weight: 700;
  font-size: 15px;
  line-height: 40px;
  color: #9393aa;
  text-align: center;
}

.prescription_table_body_row {
  height: 56px;
  border-bottom: 1px solid #eceff4;
}

.prescription_table_body_text {
  font-family: "Mulish";
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 40px;
  color: #1e1f20;
  /* padding-left: 20px !important; */
}

.prescription_table_body_text input {
  height: 38px;
  background: #ffffff;
  border: 1px solid #eceff4;
  border-radius: 10px;
  /* padding: 5px !important; */
}

.prescription_table_bottom_card {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.prescription_left_align {
  display: flex;
}

.prescription_result_text {
  font-family: "Mulish";
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
}

.result_declared_text {
  font-family: "Mulish";
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 24px;
  color: #9393aa;
}

.table_next_btn {
  background: linear-gradient(45deg, #3093bb 0%, #82dbff 100%);
  border-radius: 12px;
  font-style: normal;
  font-weight: 700;
  font-size: 15px;
  line-height: 24px;
  text-align: center;
  color: #ffff;
  padding: 10px 60px;
  border: none;
}

.generate-prescription-modal .modal-dialog {
  max-width: unset;
}

.generate-prescription-modal .modal-content {
  width: 100%;
  max-width: 715px;
  margin: auto;
  border: none;
}

.prescription-modal-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #24b2b2;
  color: #ffffff;
  text-align: right;
  padding-bottom: 0px;
}

.prescription_appoinment_input {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 5px 10px;
}

.prescription_table_appoinment_input {
  padding: 5px 10px;
}

.prescription_appoinment_input .prescription-left-text {
  font-family: "Mulish";
  font-style: normal;
  font-weight: 800 !important;
  font-size: 14px;
  color: #1e1f20;
  margin-bottom: 5px !important;
}

.prescription_appoinment_input span,
.prescription_table_appoinment_input span {
  font-family: "Mulish";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  color: #9393aa;
}

.prescription_appoinment_input .prescription-right-text {
  font-family: "Mulish";
  font-style: normal;
  font-weight: 800 !important;
  font-size: 14px;
  color: #1e1f20;
  text-align: right;
  margin-bottom: 5px !important;
  width: 130px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.text-name span {
  font-family: "Mulish";
  font-style: normal;
  font-weight: 700;
  font-size: 15px;
  line-height: 24px;
  text-align: right;
  color: #1e1f20;
}

.prescription-btn-modal-footer {
  border: none;
  width: 100%;
}

/*------------------------------------------------Prescription one css end -----------------------------------------------*/

/*------------------------------------------------Setting Css Start------------------------------------------------*/
.settings_title {
  font-family: "Mulish";
  font-style: normal;
  font-weight: 800;
  font-size: 24px;
  line-height: 40px;
  color: #1e1f20;
}

.settings_tab_card {
  background: #ffffff;
  padding: 20px 16px 20px 16px;
  height: 100%;
}

.settings_option_text_active {
  font-family: "Mulish";
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 24px;
  color: #3093bb;
  margin-left: 20px;
}

.setting_arrow {
  margin-top: 5px;
  float: right;
}

.setting_option_icon {
  margin-top: -7px;
}

.setting_profile_card_head {
  background: #ffffff;
  box-shadow: 0px 5px 10px rgba(0, 64, 128, 0.04);
  border-radius: 12px 12px 0 0;
  padding: 15px 20px;
  border-bottom: 1px solid #eceff4;
}

.setting_profile_title {
  font-family: "Mulish";
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 32px;
  color: #1e1f20;
  margin-top: 5px;
  margin-bottom: 0;
}

.setting_profile_title::before {
  content: "";
  background: #f8446c;
  border: 1px solid #f8446c;
  padding: 3px;
  border-radius: 10px;
  margin-right: 10px;
}

.setting_profile_btn {
  background: #ffffff;
  border: 1px solid #eceff4;
  border-radius: 8px;
  font-family: "Mulish";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #1e1f20;
  padding: 8px 15px;
}

.setting_profile_btn_icon {
  margin-right: 15px;
  width: 18px;
  margin-top: -5px;
}

.setting_profile_card_body {
  background: #ffffff;
  box-shadow: 0px 5px 10px rgba(0, 64, 128, 0.04);
  border-radius: 0 0 12px 12px;
  padding: 25px;
}

.setting_form_title {
  font-family: Mulish;
  font-size: 16px;
  color: #000;
  line-height: 20px;
  font-weight: 600;
}

.setting_consult_fee_subtext {
  font-family: "Mulish";
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  line-height: 16px;
  color: #f8446c;
}

.setting_time_slot_title {
  font-family: "Mulish";
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 26px;
  color: #1e1f20;
}

.setting_time_slot_title::before {
  content: "";
  background: #24b2b2;
  border: 1px solid #24b2b2;
  padding: 3px;
  border-radius: 10px;
  margin-right: 10px;
}

.setting_time_slot_day {
  font-family: "Mulish";
  font-style: normal;
  font-weight: 700;
  font-size: 17px;
  line-height: 24px;
  color: #1e1f20;
}

.setting_ul {
  padding: 0px;
}

.setting_tab_option_active_bg {
  background: #f8fbff;
  border-radius: 12px;
  padding: 13px 15px;
}

.setting_watch_icon {
  margin-top: -50px;
  background: #fff;
  float: right;
  margin-right: 10px;
}

.setting_watch_icon_2 {
  margin-top: -50px;
  background: #fff;
  float: right;
  margin-right: 10px;
}

/*-------------------------------------------------Setting Css End-------------------------------------------------*/
/*-------------------------------------------------Privacy Policy Css Start-------------------------------------------------*/

/* width */
.privacypolicy-paragraph::-webkit-scrollbar {
  width: 6px;
}

/* Track */
.privacypolicy-paragraph::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
.privacypolicy-paragraph::-webkit-scrollbar-thumb {
  background: rgb(206, 202, 202);
}

/* Handle on hover */
.privacypolicy-paragraph::-webkit-scrollbar-thumb:hover {
  background: rgb(185, 183, 183);
}

.privacypolicy_container {
  background: #f8fbff;
  padding: 30px 100px;
}

.privacypolicy_card_box {
  background: #ffffff;
  box-shadow: 0px 5px 10px rgb(0 64 128 / 4%);
  border-radius: 12px 12px 0px 0px;
  padding: 24px 20px;
  height: 650px;
}

.privacypolicy-paragraph {
  padding-right: 20px;
  height: 500px;
  overflow-y: auto;
}

.privacy_policy_title {
  font-family: "Mulish";
  font-style: normal;
  font-weight: 800;
  font-size: 24px;
  line-height: 40px;
  color: #1e1f20;
}

.privacy_policy_content h5 {
  font-family: "Mulish";
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 32px;
  color: #1e1f20;
}

.privacy_policy_content h6 {
  font-family: "Mulish";
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  line-height: 24px;
  text-align: right;
  text-transform: uppercase;
  color: #9393aa;
}

.privacy_policy_content .date {
  font-family: "Mulish";
  font-style: normal;
  font-weight: 700;
  font-size: 15px;
  line-height: 24px;
  text-align: right;
  color: #1e1f20;
}

.privacy-policy-text {
  font-family: "Mulish";
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 24px;
}

/*-------------------------------------------------Privacy Policy Css End-------------------------------------------------*/
/*-------------------------------------------------Terms and Conditon Css start-------------------------------------------------*/
/* width */
.termscondition-paragraph::-webkit-scrollbar {
  width: 6px;
}

/* Track */
.termscondition-paragraph::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
.termscondition-paragraph::-webkit-scrollbar-thumb {
  background: rgb(206, 202, 202);
}

/* Handle on hover */
.termscondition-paragraph::-webkit-scrollbar-thumb:hover {
  background: rgb(185, 183, 183);
}

.termscondition_container {
  background: #f8fbff;
  padding: 30px 100px;
}

.termscondition_card_box {
  background: #ffffff;
  box-shadow: 0px 5px 10px rgb(0 64 128 / 4%);
  border-radius: 12px 12px 0px 0px;
  padding: 24px 20px;
  height: 650px;
}

.termscondition-paragraph {
  padding-right: 20px;
  height: 500px;
  overflow-y: auto;
}

.terms_condition_title {
  font-family: "Mulish";
  font-style: normal;
  font-weight: 800;
  font-size: 24px;
  line-height: 40px;
  color: #1e1f20;
}

.terms_condition_content h5 {
  font-family: "Mulish";
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 32px;
  color: #1e1f20;
}

.terms_condition_content h6 {
  font-family: "Mulish";
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  line-height: 24px;
  text-align: right;
  text-transform: uppercase;
  color: #9393aa;
}

.terms-condition-heading {
  font-family: "Mulish";
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 24px;
  margin-bottom: 0px;
}

.terms_condition_content .date {
  font-family: "Mulish";
  font-style: normal;
  font-weight: 700;
  font-size: 15px;
  line-height: 24px;
  text-align: right;
  color: #1e1f20;
}

.terms-condition-text {
  font-family: "Mulish";
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 24px;
}

/*-------------------------------------------------Terms and Condition Css  End-------------------------------------------------*/

/*-------------------------------------------------Support Css Start-------------------------------------------------*/
/* width */
.support-paragraph::-webkit-scrollbar {
  width: 6px;
}

/* Track */
.support-paragraph::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
.support-paragraph::-webkit-scrollbar-thumb {
  background: rgb(206, 202, 202);
}

/* Handle on hover */
.support-paragraph::-webkit-scrollbar-thumb:hover {
  background: rgb(185, 183, 183);
}

.support_container {
  background: #f8fbff;
  padding: 30px 100px;
}

.support_card_box {
  background: #ffffff;
  box-shadow: 0px 5px 10px rgb(0 64 128 / 4%);
  border-radius: 12px 12px 0px 0px;
  padding: 24px 20px;
  padding-bottom: 0;
  padding-left: 0;
  padding-right: 0;
  height: auto;
}

.support-paragraph {
  padding-right: 20px;
  height: 500px;
  overflow-y: scroll;
}

.support_top_heading_card {
  padding-left: 20px;
  padding-right: 20px;
}

.support_bootom_card {
  background: #ffffff;
  box-shadow: 0px -5px 10px rgba(0, 64, 128, 0.04);
  border-radius: 0px 0px 12px 12px;
  padding: 10px 20px;
}

.support_content .date {
  font-family: "Mulish";
  font-style: normal;
  font-weight: 700;
  font-size: 15px;
  line-height: 24px;
  text-align: right;
  color: #1e1f20;
}

.support_bootom_card h5 {
  font-family: "Mulish";
  font-style: normal;
  font-weight: 700;
  font-size: 15px;
  line-height: 24px;
  color: #1e1f20;
  text-align: left;
  margin-bottom: 0;
  padding-top: 5px;
}

.support_bootom_card h6 {
  font-family: "Mulish";
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  text-transform: uppercase;
  color: #9393aa;
  text-align: left;
  margin-bottom: 0;
  padding-top: 5px;
}

.support_content .faq-text {
  font-family: "Mulish";
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  text-transform: uppercase;
  color: #9393aa;
  padding: 0px 20px;
}

.support-accordion-header button {
  font-family: "Mulish";
  font-style: normal;
  font-weight: 500 !important;
  font-size: 24px !important;
  line-height: 40px !important;
  color: #1e1f20 !important;
  margin-left: 10px;
  margin-right: 10px;
}

/* .support-accordion-header button:focus:not(:focus-visible) {
  outline: unset;
}
.support-paragraph .accordion-item:first-of-type .accordion-button {
  border-top-left-radius: unset;
  border-top-right-radius: unset;
} */

.support-accordion-header .bullets {
  background: #24b2b2;
  width: 9px;
  height: 9px;
  border-radius: 50%;
}

.accordion-collapse .support-accordion-body-text {
  font-family: "Mulish";
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 26px;
  margin: 5px 20px;
  color: #1e1f20;
}

.support_result_text {
  font-family: "Mulish";
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 24px;
  color: #fc9875;
}

.support-search {
  width: auto;
  background: #f8fbff;
}

/*-------------------------------------------------Support Css End-------------------------------------------------*/
/*-------------------------------------------------Setting Css start-------------------------------------------------*/
.setting_bank_title {
  font-family: "Mulish";
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 32px;
  color: #1e1f20;
  margin-top: 5px;
  margin-bottom: 0;
}

.setting_bank_title::before {
  content: "";
  background: #fc9875;
  border: 1px solid #fc9875;
  padding: 3px;
  border-radius: 10px;
  margin-right: 10px;
}

.setting_change_password {
  font-family: "Mulish";
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 32px;
  color: #1e1f20;
  margin-top: 5px;
  margin-bottom: 0;
}

.setting_change_password::before {
  content: "";
  background: #3093bb;
  border: 1px solid #3093bb;
  padding: 3px;
  border-radius: 10px;
  margin-right: 10px;
}

.setting_change_mobile {
  font-family: "Mulish";
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 32px;
  color: #1e1f20;
  margin-top: 5px;
  margin-bottom: 0;
}

.setting_change_mobile::before {
  content: "";
  background: #f8446c;
  border: 1px solid #f8446c;
  padding: 3px;
  border-radius: 10px;
  margin-right: 10px;
}

.send_otp {
  font-family: "Mulish";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 16px;
  color: #f8446c;
  position: absolute;
  float: right;
  right: 20px;
  margin-top: -33px;
}

.update_number {
  font-family: "Mulish";
  font-weight: 600;
  font-size: 15px;
  line-height: 24px;
  color: #f8446c;
}

.ok_btn {
  font-family: "Mulish";
  font-weight: 600;
  font-size: 15px;
  background: linear-gradient(45deg, #3093bb 0%, #82dbff 100%);
  border: 1px solid #e0e0e0;
  border-radius: 12px;
  color: #fff;
  width: 100%;
  padding: 10px 30px;
}

/*-------------------------------------------------Setting Css End-------------------------------------------------*/

/*-------------------------------------------------Reupload Details Css Start-------------------------------------------------*/

.reupload_details_container {
  background: #f8fbff;
  padding: 30px 100px;
}

.reupload_details_card_box {
  background: #ffffff;
  box-shadow: 0px 5px 10px rgb(0 64 128 / 4%);
  border-radius: 12px 12px 0px 0px;
  padding: 24px 20px;
}

.reupload_details_title {
  font-family: "Mulish";
  font-style: normal;
  font-weight: 800;
  font-size: 24px;
  line-height: 40px;
  color: #1e1f20;
}

.reupload_detals_pretitle {
  font-family: "Mulish";
  font-style: normal;
  font-weight: 700;
  font-size: 17px;
  line-height: 26px;
  color: #1e1f20;
}

.attach_file_button {
  width: 30%;
}

.submit_save_btn {
  font-family: "Mulish";
  font-weight: 700;
  font-size: 16px;
  background: linear-gradient(45deg, #3093bb 0%, #82dbff 100%);
  width: 137px;
  padding: 15px 20px;
  border: none;
  border-radius: 12px;
  color: #fff;
  margin-top: 40px;
  margin-left: 10px;
}

/*-------------------------------------------------Reupload Detals Css  End-------------------------------------------------*/
/*------------------------------------------------- Upcoming consultation  Css  End-------------------------------------------------*/
.share_label {
  font-family: "Mulish";
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  line-height: 16px;
  color: #1e1f20;
}

.consultationlink {
  font-family: "Mulish";
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  line-height: 16px;
  color: #f8446c;
}

/*-------------------------------------------------Upcoming consultation Css End-------------------------------------------------*/

/*-------------------------------------------------Chating Css Start-------------------------------------------------*/
#chat-container {
  display: grid;
  grid:
    "search-container chat-title" 71px "conversation-list chat-message-list" 1fr;
  min-width: 1000px;
  max-height: 800px;
  width: 100%;
  height: 100%;
  overflow-y: auto;
  background: #ffffff;
  box-shadow: 0px 5px 10px rgba(0, 64, 128, 0.04);
  border-radius: 12px;
}

#chat-container ::-webkit-scrollbar {
  display: none;
}

#search-container {
  grid-area: search-container;
  border-bottom: 1px solid #eceff4;
  border-right: 1px solid #eceff4;
}

#search-container input {
  display: flex;
  flex-direction: row;
  padding: 5px 20px 5px 50px;
  align-items: center;
  height: 44px;
  max-width: 278px;
  width: 278px;
  margin: 23px 13px;
  background: #f8fbff;
  border-radius: 8px;
  border: none;
}

#conversation-list {
  grid-area: conversation-list;
  overflow-y: auto;
  overflow-x: hidden;
  border-right: 1px solid #eceff4;
  max-height: 75vh;
  min-height: 75vh;
}

.conversation {
  display: grid;
  grid-template-columns: 40px 1fr max-content;
  grid-gap: 7px;
  border-bottom: 1px solid #eceff4;
  padding: 15px;
  align-items: center;
}

.conversation.active,
.conversation:hover {
  background: #eceff4;
  cursor: pointer;
}

.conversation>img {
  grid-row: span 2;
  height: 40px;
  width: 40px;
  border-radius: 100%;
}

.conversation .title-text {
  font-weight: 700;
  font-size: 15px;
  line-height: 24px;
  color: #1e1f20;
}

.conversation .created-date {
  font-weight: 600;
  font-size: 12px;
  line-height: 12px;
  color: #9393aa;
}

.conversation .conversation-message {
  grid-column: span 2;
  padding-left: 5px;
  font-weight: 600;
  font-size: 12px;
  line-height: 12px;
  color: #9393aa;
}

#new-message-container {
  display: grid;
  grid: 40px / 40px;
  align-content: center;
  border-top: 1px solid;
  border-radius: 0 0 0 10px;
  padding: 0 15px;
}

#new-message-container button {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 100%;
  text-decoration: none;
  font-size: 3.6rem;
  line-height: 3.6rem;
  outline: none;
  border: none;
  cursor: pointer;
}

#chat-title {
  display: flex;
  justify-content: space-between;
  align-content: center;
  align-items: center;
  grid-area: chat-title;
  padding: 10px 20px;
  border-bottom: 1px solid #eceff4;
}

#chat-title .title-text {
  font-weight: 700;
  font-size: 15px;
  line-height: 24px;
  color: #1e1f20;
}

.created-gender {
  font-weight: 600;
  font-size: 12px;
  line-height: 12px;
  color: #9393aa;
}

#chat-message-list .created-date {
  font-weight: 600;
  font-size: 12px;
  line-height: 12px;
  color: #9393aa;
  margin: 10px auto;
  margin-bottom: 32px;
  text-align: center;
}

#chat-title .conversation-online {
  grid-column: span 2;
  padding-left: 5px;
  font-weight: 600;
  font-size: 12px;
  line-height: 12px;
  color: #9393aa;
}

.rightchat-title {
  position: relative;
  display: flex;
  gap: 15px;
  align-items: center;
}

.dropdown-container a {
  text-decoration: none;
  color: #1e1f20;
}

.dropdown-container a div {
  padding: 2px;
}

.dropdown-container .dropdown {
  padding: 20px 0px 20px 16px;
  width: 173px;
  position: absolute;
  top: 30px;
  right: 10px;
  background: #ffffff;
  /* card shadow */
  box-shadow: 0px 5px 10px rgba(0, 64, 128, 0.04);
  outline: none;
  opacity: 0;
  z-index: 9;
  max-height: 0;
  transition: opacity 0.1s;
  max-height: 5s;
}

.dropdown-container .dropdown2 {
  padding: 20px 0px 20px 16px;
  width: 173px;
  position: absolute;
  top: 30px;
  right: 10px;
  background: #ffffff;
  /* card shadow */
  box-shadow: 0px 5px 10px rgba(0, 64, 128, 0.04);
  outline: none;
  opacity: 0;
  z-index: 9;
  max-height: 0;
  transition: opacity 0.1s;
  max-height: 5s;
}

.dropdown-container:focus {
  outline: none;
}

.dropdown-container:focus .dropdown {
  opacity: 1;
  z-index: 100;
  max-height: 100vh;
  transition: opacity 0.2s;
  max-height: 0.2s;
}

#chat-message-list {
  grid-area: chat-message-list;
  display: flex;
  flex-direction: column;
  padding: 0 0px;
  position: relative;
}

.chat-message-list-inner {
  min-height: 68vh;
  max-height: 68vh;
  overflow-y: auto;
  padding: 30px 20px 24px 20px;
  /* margin: 0 0 35px 0; */
  margin-top: 50px;
}

.dashboard .chat_box_bg .chat-message-list-inner {
  min-height: 70vh;
  max-height: 70vh;
}

.message-row {
  display: grid;
  grid-template-columns: 55%;
  margin-bottom: 10px;
}

.message-row>.message-content {
  display: grid;
}

.message-row>.message-content>.message-time {
  font-weight: 600;
  font-size: 10px;
  line-height: 12px;
  color: #9393aa;
}

.message-row.you-message {
  justify-content: end;
}

.message-row.you-message>.message-content {
  justify-items: end;
}

.message-row.you-message>.message-content>.message-text {
  background: #f8fbff;
  border-radius: 12px;
  padding: 16px 24px;
  font-weight: 700;
  font-size: 15px;
  line-height: 24px;
  color: #1e1f20;
}

.message-row.other-message {
  justify-items: start;
}

.message-row.other-message>.message-content>.message-text {
  background: #3093bb;
  border-radius: 12px;
  color: #fff;
  padding: 16px 24px;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
}

.content__footer {
  padding-top: 10px;
  display: flex;
  align-items: center;
  width: 100%;
}

.sendNewMessage {
  background: #f8fbff;
  border-radius: 12px;
  display: flex;
  justify-content: space-between;
  padding: 5px 10px;
  margin-left: 30px;
  margin-bottom: 10px;
  width: 80%;
}

.sendNewMessage button {
  width: 36px;
  height: 36px;
  border: none;
  font-size: 16px;
  margin-left: 5px;
  margin-right: 5px;
  color: #96a0b5;
  padding: 0;
  background: none;
  border-radius: 5px;
  line-height: 36px;
  transition: all 0.3s cubic-bezier(0.88, 0.19, 0.37, 1.11);
}

.sendNewMessage button i {
  display: block;
}

.sendNewMessage input {
  flex-grow: 1;
  padding: 0 15px;
  background-color: #f8fbff;
  border: none;
  outline: none;
}

#sendMsgBtn {
  color: #96a0b5;
}

.attach-dropdown {
  background-color: #f8fbff !important;
  color: #96a0b5 !important;
}

.attach-dropdown:hover {
  background-color: #f8fbff;
  color: #96a0b5;
}

.attach-dropdown::after {
  content: unset;
}

.upcomming_consult_title {
  font-family: "Mulish";
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 32px;
  color: #1e1f20;
}

.chat_box_bg {
  background: #ffffff;
  box-shadow: 0px 5px 10px rgba(0, 64, 128, 0.04);
  border-radius: 12px;
  padding-bottom: 20px;
}

::-webkit-scrollbar {
  width: 4px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #808080;
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #e8e6e6;
}

.upcomming_consult_chat_list {
  border-right: 1px solid #eceff4;
  min-height: 89vh;
  max-height: 90vh;
  overflow-y: auto;
}

.chat_list_box {
  padding: 14px 20px;
}

.chat_list_bottom_border {
  border-bottom: 1px solid #eceff4;
}

.chat_list_box_active {
  padding: 14px 20px;
  background: rgba(36, 178, 178, 0.07);
  border-radius: 12px 0px 0px 0px;
  border: 1px solid #eceff4;
}

.chat_list_id {
  font-family: "Mulish";
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 12px;
  color: #9393aa;
  margin-bottom: 10px;
}

.chat_list_title {
  font-family: "Mulish";
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 24px;
  color: #1e1f20;
  margin-right: 10px;
}

.chat_list_subtitle {
  font-family: "Mulish";
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  line-height: 12px;
  display: flex;
  align-items: center;
  color: #9393aa;
}

.chat_title_box {
  display: inline-flex;
}

.date_time {
  font-family: "Mulish";
  font-style: normal;
  font-weight: 800;
  font-size: 12px;
  line-height: 12px;
  color: #1e1f20;
  margin-top: 7px;
  margin-bottom: 0;
}

.time_left_box {
  background: rgba(248, 68, 108, 0.1);
  border-radius: 4px;
  padding: 7px;
  font-family: "Mulish";
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 12px;
  color: #f8446c;
  text-align: center;
  margin-top: 23px;
}

.profile_name_box {
  border-bottom: 1px solid #eceff4;
  padding: 18px 20px;
  border: none;
}

.profile_card_name {
  font-family: "Mulish";
  font-style: normal;
  font-weight: 700;
  font-size: 17px;
  line-height: 26px;
  color: #1e1f20;
  margin-right: 12px;
}

.profile_name_subtitle {
  font-family: "Mulish";
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 12px;
  align-items: center;
  color: #9393aa;
  margin-top: 7px;
}

.profile_namesubtitle_box {
  display: inline-flex;
}

.padding_left_0 {
  padding-left: 0px;
}

.padding_right_0 {
  padding-right: 0px;
}

.online_text {
  font-family: "Mulish";
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 12px;
  display: flex;
  align-items: center;
  color: #9393aa;
}

.chat-head-right {
  /* display: flex; */
  align-items: center;
  justify-content: space-around;
}

.call_btn {
  background: #24b2b2;
  border-radius: 8px;
  border: none;
  padding: 6px 11px 10px 11px;

  margin-top: 7px;
  margin-right: 24px;
}

.call_btn:hover {
  background: #24b2b2 !important;
  border-radius: 8px !important;
  border: none !important;
  padding: 6px 11px 10px 11px !important;

  margin-top: 7px !important;
  margin-right: 24px;
}

.mark_complete {
  background: #ffffff;
  border: 1px solid rgba(137, 152, 189, 0.2);
  border-radius: 8px;
  font-family: "Mulish";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
  color: #f8446c;
  padding: 8px 16px;
  margin-top: 7px;
  margin-right: 20px;
}

.mark_complete:hover {
  background: #ffffff;
  border: 1px solid rgba(137, 152, 189, 0.2);
  border-radius: 8px;
  font-family: "Mulish";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
  color: #f8446c;
  padding: 8px 16px;
  margin-top: 7px;
  /* margin-right: 20px; */
}

.upcomming_consult_chat_message_box {}

.sender_msg_box {
  background: #3093bb;
  border-radius: 12px;
  color: #fff;
  padding: 16px 24px;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
}

.sender_name_title {
  font-family: "Mulish";
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 24px;
  color: #fff;
  margin-bottom: 0;
}

.sender_name_subtitle {
  font-family: "Mulish";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: #3093bb;
  margin-bottom: 3px;
}

.sender_message_time {
  font-weight: 600;
  font-size: 10px;
  line-height: 12px;
  color: #9393aa;
  text-align: right;
  margin-top: 6px;
}

.sender_text_title {
  font-family: "Mulish";
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  color: #fff;
  margin-bottom: 0px;
}

.client_msg_box {
  background: #f8fbff;
  border-radius: 12px;
  padding: 16px 24px;
}

.client_text_title {
  font-family: "Mulish";
  padding: 3px 10px 7px 10px;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  color: #1e1f20;
  margin-bottom: 0;
}

.client_message_time {
  font-weight: 600;
  font-size: 10px;
  line-height: 12px;
  color: #9393aa;
  text-align: left;
  margin-top: 6px;
}

.userinfo_title {
  font-family: "Mulish";
  font-style: normal;
  font-weight: 700;
  font-size: 17px;
  line-height: 26px;
  color: #1e1f20;
  padding-top: 14px;
  padding-bottom: 14px;
  margin-bottom: 0;
}

.user_info_close {
  padding: 10px;
  float: right;
}

.userinfo_box {
  padding: 20px 24px;
}

.userprofile_name_title {
  font-family: "Mulish";
  font-style: normal;
  font-weight: 700;
  font-size: 17px;
  line-height: 26px;
  color: #1e1f20;
  margin-top: 10px;
}

.userprofile_name_subtitle {
  font-family: "Mulish";
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 12px;
  align-items: center;
  color: #9393aa;
}

.userprofile_hr_bottom {
  border-bottom: 1px solid #eceff4;
  margin-top: 24px;
}

.userinfo_profile_title {
  font-family: "Mulish";
  font-style: normal;
  font-weight: 700;
  font-size: 17px;
  line-height: 26px;
  color: #1e1f20;
  margin-bottom: 15px;
}

.userprofile_data_title {
  font-family: "Mulish";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
  color: #9393aa;
}

.userprofile_data_value {
  font-family: "Mulish";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
  text-align: right;
  color: #1e1f20;
}

.userprofile_section_hr_bottom {
  border-bottom: 1px solid #eceff4;
  margin-top: 14px;
}

.share_file_title {
  font-family: "Mulish";
  font-style: normal;
  font-weight: 700;
  font-size: 17px;
  line-height: 26px;
  color: #1e1f20;
  margin-top: 3px;
}

.share_file_box {
  display: inline-flex;
}

.share_file_icon {
  width: 40px;
  height: 47px;
  margin-right: 15px;
}

.share_file_name {
  font-family: "Mulish";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
  color: #1e1f20;
  margin-bottom: 3px;
}

.share_file_date {
  font-family: "Mulish";
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 12px;
  color: #9393aa;
}

.share_file_size {
  font-family: "Mulish";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 12px;
  color: #9393aa;
  text-align: right;
  margin-top: 15px;
}

.share_img_title {
  font-family: "Mulish";
  font-style: normal;
  font-weight: 700;
  font-size: 17px;
  line-height: 26px;
  color: #292c38;
}

.share_img_box {
  display: inline-flex;
  column-gap: 15px;
  row-gap: 15px;
  flex-wrap: wrap;
}

.share_img_size {
  width: 70px;
}

.dashboard_title {
  font-family: "Mulish";
  font-style: normal;
  font-weight: 800;
  font-size: 24px;
  line-height: 40px;
  color: #292c38;
}

.chat_profile_name_box {
  border: 1px solid #eceff4;
  padding: 18px 20px;
}

.chat_search_icon {
  position: absolute;
  margin-top: -49px;
  margin-left: 33px;
}

.chatting_search {
  margin-left: 30px;
  margin-top: 0px;
  position: absolute;
}

.chat_message_count {
  background: linear-gradient(45deg, #3093bb 0%, #82dbff 100%);
  border-radius: 1000px;
  float: right;
  margin-left: 22px;
  padding: 0px 7.5px;
  height: 22px;
  width: 22px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: 0.2px;
  color: #ffffff;
  margin-top: 10px;
}

.more_info_btn {
  background: #ffffff;
  box-shadow: 0px 5px 10px rgba(0, 64, 128, 0.04);
  border-radius: 12px;
  margin-top: 7px;
  border: none;
  margin-left: 24px;
}

.chat_mark_complete_btn {
  background: #ffffff;
  border: 1px solid rgba(137, 152, 189, 0.2);
  border-radius: 8px;
  font-family: "Mulish";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
  color: #f8446c;
  padding: 8px 16px;
  margin-top: 7px;
  float: right;
  margin-right: 24px;
}

.chat_contact_list_box {
  padding: 24.5px 30px;
  border-bottom: 1px solid #eceff4;
}

.chat_contact_list_box_active {
  background: rgba(36, 178, 178, 0.07);
  border-radius: 0px;
}

.chat_list_display_box {
  display: inline-flex;
}

.chat_user_img {
  width: 40px;
  height: 40px;
  margin-right: 15px;
}

.chat_user_name {
  font-family: "Mulish";
  font-style: normal;
  font-weight: 700;
  font-size: 15px;
  line-height: 24px;
  color: #1e1f20;
  margin-bottom: 5px;
}

.chat_user_last_msg {
  font-family: "Mulish";
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 12px;
  color: #9393aa;
}

.chat_time {
  font-family: "Mulish";
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 12px;
  text-align: right;
  color: #9393aa;
}

.chat_contact_list_hr {
  border-bottom: 1px solid #eceff4;
}

.attach_file_name {
  font-family: "Mulish";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  color: #1e1f20;
  margin-bottom: 4px;
  margin-right: 15px;
}

.other-message .attach_file_name {
  color: #f9f871;
}

.attach_file_size {
  font-family: "Mulish";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 12px;
  color: #9393aa;
}

.other-message .attach_file_size {
  color: #f8f8f8;
}

.attach_file_box {
  display: inline-flex;
}

.file_icon {
  height: 34px;
  width: 25px;
  margin-right: 15px;
  margin-top: 4px;
}

.file_download_icon {
  width: 18px;
  height: 18px;
  margin-top: 15px;
  cursor: pointer;
}

.client_msg_title {
  font-family: "Mulish";
  font-style: normal;
  font-weight: 700;
  font-size: 15px;
  line-height: 24px;
  color: #1e1f20;
  margin-bottom: 0;
}

.client_msg_subtitle {
  font-family: "Mulish";
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 24px;
  color: #1e1f20;
  margin-bottom: 0;
}

.msg_view_btn {
  background: #3093bb;
  border-radius: 4px;
  font-family: "Mulish";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  text-decoration: none;
  color: #f2f2f2;
  border: 0;
  padding: 1px 10px;
  height: 25px;
  margin-left: 15px;
}

.msg_view_btn:hover {
  color: #f2f2f2;
}

.msg_view_box {
  display: inline-flex;
}

.chat_section_rightr_border {
  border-right: 1px solid #eceff4;
}

.attach_items_box {
  background: #eceff4;
  min-height: 79vh;
  max-height: 90vh;
  padding: 30px 20px 24px 20px;
}

.attach_cross {
  float: right;
}

.attach_img_box img {
  max-width: 500px;
  max-height: 500px;
  border-radius: 8px;
}

.attach_img_box {
  padding: 10%;
  height: 100%;
}

.attach_input_btn_box {
  display: inline-flex;
  width: 96.5%;
}

.attach_input_box {
  padding: 16px 24px;
  width: 100%;
}

.attach_send_btn {
  background: linear-gradient(45deg, #3093bb 0%, #82dbff 100%);
  box-shadow: 0px 5px 10px rgba(0, 64, 128, 0.04);
  border-radius: 12px;
  border: none;
  padding: 10px;
  padding: 14px 20px 18px 21px;
  height: 56px;
}

.attach_doc_img_box {
  background: #ffffff;
  border-radius: 12px;
  width: 250px;
  height: 250px;
  display: flex;
  padding: 42px 35px;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.attch_doc_img {
  margin-bottom: 20px;
  padding: 10px;
  width: 80px;
}

.attch_doc_title {
  font-family: "Mulish";
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 24px;
  text-align: initial;
  color: #1e1f20;
}

.attach_doc_size {
  font-family: "Mulish";
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 24px;
  color: #9393aa;
}

.chatuserimg {
  width: 64px;
  height: 64px;
}

.attachment_img {
  width: 384px;
  height: 370px;
}

/*-------------------------------------------------Chatting Css End-------------------------------------------------*/
.sharelinkmain {
  border-radius: 12px;
  background: #FFF;
  box-shadow: 0px 5px 10px 0px rgba(0, 64, 128, 0.04);
  height: 768px;
  flex-shrink: 0;
}

.sharelinkfont {
  color: var(--text-1, #1E1F20);
  font-family: Mulish;
  font-size: 24px;
  font-style: normal;
  font-weight: 800;
  line-height: 40px;
  /* 166.667% */
}

.sharelinkpadding {
  padding: 40px 30px 0px 40px;
  display: flex;
  gap: 10px;
}

.sharelinksecond {
  padding: 5px 0px 0px 40px;
}

.colordiv {
  width: 6px;
  height: 30px;
  flex-shrink: 0;
  border-radius: 2px;
  background: #F8446C;
}

.sharetext {
  color: #1E1F20;
  font-family: Mulish;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 32px;
}

.sharelinkp {
  color: #F8446C;
  font-family: Mulish;
  font-size: 13px;
  font-style: normal;
  font-weight: 600;
  line-height: 16px;
}

.sharelinkparagraph {
  color: #9393AA;
  font-family: Mulish;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 16px;
}

.paycontent {
  color: #1E1F20;
  font-family: Mulish;
  font-size: 13px;
  font-style: normal;
  font-weight: 600;
  line-height: 16px;
}

.firstpayment {
  display: flex;
  gap: 20px;
}

.consultionfee {
  color: #3093BB;
  font-family: Mulish;
  font-size: 15px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
}

.cons {
  color: #1E1F20;
  font-family: Mulish;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
}

.inputclass {
  width: 191px !important;
  height: 48px;
  flex-shrink: 0;
  border: 1px solid #F0F0F0;
  border-radius: 8px;
  padding-left: 10px;
}


.sharelink {
  background: #f8fbff;
  padding: 0 100px 30px 100px;
  min-height: 100vh,
}

.buttonsave {
  border-radius: 12px;
  background: var(--gradient, linear-gradient(45deg, #3093BB 0%, #82DBFF 100%));
  width: 343px;
  height: 50px;
  flex-shrink: 0;
  color: #FFF;
  text-align: center;
  font-family: Mulish;
  font-size: 15px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
  border: none;
}

.secondDiv {
  display: flex;
  border-radius: 8px;
  border: 1px solid #F0F0F0;
  background: #FFF;
  padding: 10px 12px;
  width: 380px;
  align-items: center;
  justify-content: space-between;
}

.editbtn {
  color: #F8446C;
  font-family: Mulish;
  font-size: 15px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  background-color: #FFF;
  border-radius: 8px;
  border: 1px solid #ECEFF4;
}

.secondsharelink {
  color: #1E1F20;
  font-family: Mulish;
  font-size: 13px;
  font-style: normal;
  font-weight: 600;
  line-height: 16px;
}

.textdiv {
  display: flex;
  gap: 8px;
  align-items: center;
}

.secondh2 {
  color: #1E1F20;
  font-family: Mulish;
  font-size: 15px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
}

/* Hide the default checkbox */
input[type="checkbox"].checkboxdesign {
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  width: 20px;
  height: 20px;
  background-color: #fff;
  /* Set the background color of the checkbox */
  border-radius: 50%;
  border: 1px solid #000;
  outline: none;
  cursor: pointer;
  position: relative;
}

/* Add a pseudo-element to represent the checkmark */
input[type="checkbox"].checkboxdesign::before {
  content: "\2713";
  /* Unicode character for checkmark (✓) */
  display: none;
  /* Initially hide the checkmark */
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 16px;
}

/* Show the checkmark when the checkbox is checked */
input[type="checkbox"].checkboxdesign:checked::before {
  display: block;
}

.input-container {
  position: relative;
}
.hover-button:hover {
  background: #199A8E; /* Change background color on hover */
  color: green!important; /* Change text color on hover */
}

input[type="text"] {
  width: 100%;
  height: 48px;
  padding: 10px;
  border: 1px solid #e6e8eb;
  border-radius: 5px;
  font-size: 16px;
}

input[type="date"] {
  width: 100%;
  height: 48px;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 16px;
}

input[type="email"] {
  width: 100%;
  height: 48px;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 16px;
}

input[type="password"] {
  width: 100%;
  height: 48px;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 16px;
}

#copyLinkText {
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  color: #3093BB;
  font-size: 16px;
  cursor: pointer;
}

/* Optional styling for the text on hover */
#copyLinkText:hover {
  text-decoration: underline;
}

.sharebtn {
  padding: 10px 117px;
  align-items: center;
  border: 1px solid #F0F0F0;
  border-radius: 10px;
  background-color: #FFFFFF;
  width: 385px;
  margin-top: 20px;
}

.sharebtn2 {
  padding: 10px 115px;
  align-items: center;
  border: 1px solid #F0F0F0;
  border-radius: 10px;
  background-color: #FFFFFF;
  width: 385px;
  margin-top: 20px;
}

.sharebtn3 {
  padding: 10px 30px;
  align-items: center;
  border: 1px solid #F0F0F0;
  border-radius: 10px;
  background-color: #FFFFFF;
  width: 385px;
  margin-top: 20px;
}

.shreflex {
  display: flex;
  gap: 10px;
  align-items: center;
  justify-content: center;
}

/* Your existing CSS styles */
/* ... */

/* CSS styles for the modal container */
.modal-container {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(50, 59, 75, 0.30);
  backdrop-filter: blur(2px);
  z-index: 999;
  /* Ensure the modal appears above other elements */
}

.mainModal {
  border-radius: 16px;
  background: #FFF;
  width: 391px;
  height: 308px;
}

.modalmaintext {
  color: #1E1F20;
  font-family: Mulish;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 32px;
}

.okbtn {
  border-radius: 12px;
  background: var(--gradient, linear-gradient(45deg, #3093BB 0%, #82DBFF 100%));
  width: 343px;
  height: 50px;
  flex-shrink: 0;
  border: none;
  color: #FFF;
  text-align: center;
  font-family: Mulish;
  font-size: 15px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
}

.numberdiv {
  display: flex;
  gap: 20px;
  padding-top: 15px;

}

.textnumber {
  color: #1E1F20;
  font-family: Mulish;
  font-size: 15px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  /* 160% */
  border-radius: 8px;
  background: var(--line-colour, #ECEFF4);
  padding: 8px 10px;
}

.uploadmodal {
  display: flex;
  justify-content: space-between;
}

.uploadtext {
  color: #1E1F20;
  font-family: Mulish;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 32px;
}

.uploadbtn {
  border-radius: 12px;
  border: 1px solid #E0E0E0;
  background: #FFF;
  color: #3093BB;
  text-align: center;
  font-family: Mulish;
  font-size: 15px;
  font-style: normal;
  text-decoration: none !important;
  padding-top: 8px !important;
}

.uploadbtndiv {
  display: flex;
  justify-content: space-between;
}

.closebtn {
  color: #9393AA;
  text-align: center;
  font-family: Mulish;
  font-size: 15px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
  /* 160% */
  border-radius: 12px;
  border: 1px solid #E0E0E0;
  background: #FFF;
  width: 48%;
  height: 50px;
  flex-shrink: 0;
}

.sendbtn {
  border-radius: 12px;
  border: 1px solid #E0E0E0;
  background: #F0F0F0;
  color: #9393AA;
  text-align: center;
  font-family: Mulish;
  font-size: 15px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
  width: 48%;
  height: 50px;
  flex-shrink: 0;
}

.sendbtns {
  border: 1px solid #E0E0E0;
  border-radius: 12px;
  background: var(--gradient, linear-gradient(45deg, #3093BB 0%, #82DBFF 100%));
  color: #FFF;
  text-align: center;
  font-family: Mulish;
  font-size: 15px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
  width: 48%;
  height: 50px;
  flex-shrink: 0;
}

.sheetimg {
  width: 110px;
  height: 110px;
}

.sheetimgdiv {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.textcolor {
  color: #1E1F20;
  text-align: center;
  font-family: Mulish;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  margin-bottom: 0px;
}

.dosage-controls {
  display: flex;
  border: 1px solid #ccc;
  border-radius: 8px;
  height: 50px;
}

.dosage-input {
  border: none !important;
}

.modal_455 {
  --bs-modal-width: 230px !important;
}

.carousel-image {
  height: 50vh;
  object-fit: cover;
  /* This ensures the image covers the entire height */
}

@media only screen and (min-width: 600px) and (max-width:900px) {
  .login_box {
    padding: 80px 8px !important;

  }

  .slider_1 {
    padding: 10px 0px 10px 0px;
  }

  .signin_box {
    padding: 0px;
  }

  .display_t {
    margin: 0px 20px 0px 20px;
  }

  .logo_title {
    padding-top: 50px;
  }
  .logo_box{
    width: 300px!important;
  }
}

@media only screen and (min-width: 250px) and (max-width: 600px) {
  .sub_section_2 {
    width: 100% !important;
  }

  .slider_1 {
    padding: 10px 50px 10px 10px !important;
  }

  .logo_title {
    text-align: center;
  }

  .welcome_title {
    text-align: center;
  }

  .owl-carousel {
    display: flex !important;
    flex-direction: column !important;
    justify-content: center !important;
  }

  .carousel-image {
    width: 958px !important;
  }

  .owl_banner_img_box {
    width: 100% !important;

  }

  .logo_title {
    padding-top: 10px;
  }

  .login_box {
    padding: 40px 8px !important;
   
  }

  .logo_box {
    padding: 50px 10px !important;
    width: 250px!important;
  
  }

  .carousel-image {
    display: flex;
    align-items: center;
    justify-content: center;
  }

}

/* @media only screen and (min-width:300px) and (max-width:830px) {

  .sub_section_2 {
    width: 100% !important;
  }

  .slider_1 {
    padding: 10px 50px 10px 10px !important;
  }
  .login_box{
    padding: 10px ;
  }

} */


